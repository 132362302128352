
.main-hero {
    max-width: 86.25rem;
    margin: 0 auto;
    position: relative;
    &:before {
        content: '';
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .main-hero__content {
        position: relative;
        z-index: 1;
    }
}

.main-hero__center {
    position: relative;

    @include media-breakpoint-down(sm) {
        @include columnreverse();
    }
}

    .main-hero__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        color: $white;

        @include media-breakpoint-up(md) {
            padding: 5% 4.375rem;
        }

        @include media-breakpoint-down(sm) {
            padding: 25% 1rem;
        }

        // Content asset alignmets
        &.set--alignment-middle {
            align-items: center;
            text-align: center;

            .main-hero__content {
                max-width: 38.5rem;
            }
        }

        &.set--alignment-right {
            align-items: end;
            text-align: right;
        }

        &.set--v-alignment-top {
            justify-content: flex-start;
        }

        &.set--v-alignment-middle {
            justify-content: center;
        }

        &.set--v-alignment-bottom {
            justify-content: flex-end;

            &.set--layout-large {
                @include media-breakpoint-up(md) {
                    padding-bottom: 10.87%;
                }
            }
        }
    }

        .main-hero__content {
            max-width: 31.125rem;
        }

        .main-hero__title {
            margin-bottom: .35em;
            font-weight: 200;
        }

        .main-hero__description {
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
