.home__section-3 {
    margin-top: 80px;
    overflow: hidden;
    @media (min-width: 768px) {
        margin-top: 75px;
    }
    @media (min-width: 1280px) {
        margin-top: 77px;

    }
    @media (min-width: 1920px) {
        margin-top: 98px;
    }
    
    @media (max-width: 767.98px) {
        display: block !important;
    }
    .shop-collection {
        background-color: #efede9;
        display: flex;
        flex-direction: column;
        @media (max-width: 767.98px) {
            padding: 0 20px 56px !important;
            max-width: 100%;
        }
    }

     .home-tooltip  {     
        @media (max-width: 767.98px) {
            max-width: 100%;   
            padding: 20px 20px 0; 
            background-color: #efede9;
        }
    }
}

.home-carousel__tooltip {
    .slick-prev {
        @media (min-width: 1280px) {
            top: 47.3%;
            color: $white;
            left: 29px;
        }
        @media (min-width: 1920px) {
            width: 22px;
            height: 41px;
            left: 44px;
        }
        &::before {
            display: none;
        }
        background: url('../images/icons/left-arrow-icon.svg') center center no-repeat;
        background-size: cover;
        width: 15px;
        height: 27px;
        opacity: 1;
    }

    .slick-next {
        @media (min-width: 1280px) {
            top: 47.3%;
            color: $white;
            right: 29px;
        }
        @media (min-width: 1920px) {
            width: 22px;
            height: 41px;   
            right: 44px;
        }
        &::before {
            display: none;
        }
        background: url('../images/icons/right-arrow-icon.svg') center center no-repeat;
        background-size: cover;
        width: 15px;
        height: 27px;
        opacity: 1;   
    }

    .slick-dots {
        @media (min-width: 1280px) {
            bottom: -20px;
            margin-bottom: 0;
        }
        @media (min-width: 1920px) {  
            bottom: -20px;          
        }
        button {
            &:after {
                width: 7px;
                height: 7px;
                opacity: 1;
                background: #757575;
                @media (min-width: 1920px) {
                    width: 9px;
                    height: 9px;
                }
            }
        }
        .slick-active {
            button {
                &:after {
                    background: #000000;
                }
            } 
        }
    }

    
}

.shop-collection__cta1, .shop-collection__cta2{
    font-family: 'Archivo-Light';
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: 0.42px;
    color: #5f5f5f;
    @media (min-width: 768px) {
        font-size: 12px;
        line-height: 1.92;
        letter-spacing: 0.36px;
    }
    @media (min-width: 1280px) {

    }
    @media (min-width: 1920px) {
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: 0.48px;
    }
}

.shop-collection__ctas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding-top: 22px;
    @media (min-width: 768px) {
        padding: 17px 20px 0 19px;
    }
    @media (min-width: 1280px) {
        padding: 17px 19px 0;
    }
    @media (min-width: 1920px) {
        padding: 25px 29px 0;
    }
    @media screen and (max-width: 767.98px) {
        position: relative;
        padding-bottom: 60px;
    }
}

.shop-collection__info {
    padding: 50px 0;
    @media (min-width: 768px) {
        width: 257px;
        margin: auto;
    }

    @media (min-width: 1600px) {
        width: 418px;
    }
    @media screen and (max-width: 767.98px) {
        padding: 0;
    }
}
.shop-collection__title {
    font-family: 'Archivo-Light';
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: 0.6px;
    text-align: center;
    color: $black;
    margin: 0 0 28px;
    @media (min-width: 768px) {
        font-size: 22px;
        line-height: 1.18;
        letter-spacing: 0.44px;
        margin: 0 0 12px;
    }

    @media (min-width: 1600px) {
        font-size: 35px;
        line-height: 1.14;
        letter-spacing: 0.7px;
        margin: 0 0 13px;
    }
}

.shop-collection__line {
        width: 105px;
        height: 1px;
        background-color: #bcbcbc;
        margin: auto;
        @media (min-width: 768px) {
            width: 70px;
        }
        @media (min-width: 1280px) {
        
        }
        @media (min-width: 1600px) {
            width: 105px;
        }
}

.shop-collection__content {
    font-family: 'Archivo-Light';
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: 0.48px;
    text-align: center;
    color: #656565;
    margin: 29px 0 39px;

    @media (min-width: 768px) {
        font-size: 12px;
        line-height: 1.42;
        letter-spacing: 0.36px;
        margin: 22px 0 20px;
    }

    @media (min-width: 1280px) {
    
    }

    @media (min-width: 1600px) {
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: 0.48px; 
        margin: 28px 0 38px;
    }

}

.shop-collection__cta3 {

    width: 100%;
    height: 60px;
    border-radius: 8px;
    background-color: #000;

    font-family: 'Archivo-Medium';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.11px;
    text-align: center;
    color: $white;
    display: block;
    text-transform: uppercase;
    padding: 24px 0;
    margin: auto;

    &:hover {
        color: $white;
        text-decoration: none;
    }

    &:focus{
        outline: 2px solid $blue;
    }

    @media (min-width: 768px) {
        width: 169px;
        height: 41px;
        border-radius: 6px;

        font-size: 9px;
        line-height: 0.56;
        letter-spacing: 1.58px;
        padding: 18px 0;
    }

    @media (min-width: 1280px) {
    
    }
    @media (min-width: 1600px) {
        border-radius: 8px;
        width: 225px;
        height: 60px;

        font-size: 12px;
        line-height: 1;
        letter-spacing: 2.11px;
        padding: 24px 0;
    }

}
//tooltips
.home-tooltip__carousel-item-content {
    @media (max-width: 1279.98px) {
        display: none;
    }
    .tooltip-1, .tooltip-2, .tooltip-3 {
        z-index: 2;
        &.z-index {
            z-index: 1;
        }
    }

    .tooltip-img {
        display: block;

    }
    .largetooltip-img {
        display: none;
        @media (min-width: 1920px) {
            display:block;
        }  
    }

    .white-tooltip {
        display: none !important;
        position: absolute;
        left: 0px;
        top: 1px;
    }

    .white-tooltip-large {
        display: none !important;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .tooltip-1, .tooltip-2, .tooltip-3 {
        .tooltip-content {
            display: none;
        }
    }
    
    .tooltip-1, .tooltip-2, .tooltip-3 {
         &:hover, &:focus {
            cursor: pointer;
        
            >.white-tooltip {
                @media (min-width: 1280px)  {
                    display: inline !important;
                }  
            }

            >.tooltip-img {
                @media (min-width: 1280px) {
                    visibility: hidden;
                } 
            }
        }
    } 
    
    .tooltip-img {
        &.hide {
            visibility: hidden;
        }
        &.show {
            display: block;
        }
    }

    .white-tooltip {
        &.hide {
            display: none !important;
        }
        &.show {
            display: inline !important;
        }
    }
    

    .tooltip-1-content, .tooltip-2-content, .tooltip-3-content {
        
        @media (min-width: 768px) {

        }
        @media (min-width: 1280px) {
            width: 295px;
            padding: 22px 16px 17px 17px;
            border-radius: 6px;
            background-color: $white;
            z-index: 10;
        }
        @media (min-width: 1920px) {
            width: 401px;
            border-radius: 8px;
            padding: 37px 20px 20px;
        }
    }

    .tooltip-1-content {
        @media (min-width: 1280px) {
            bottom: -15px;
            right: 23px;
        }
        @media (min-width: 1920px) {
            right: 41px;
        }
    }

    .tooltip-3-content {
        @media (min-width: 1280px) {
            right: -140px;
            bottom: 24px;
        }
        @media (min-width: 1920px) {
            bottom: 35px;
        }
    }

    .tooltip-2-content {
        @media (min-width: 1280px) {
            right: -65px;
            bottom: 24px;
        }
        @media (min-width: 1920px) {
            right: -100px;
            bottom: 35px;
        }
    }

    .tooltip-title {
        @media (min-width: 768px) {
        }
        @media (min-width: 1280px) {
            font-family: 'Butler-Light';
            font-size: 24px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: $black;
            text-transform: uppercase;
        }
        @media (min-width: 1920px) {
            font-size: 30px;
        }
    }
    .tooltip-description {
        @media (min-width: 768px) {
        }
        @media (min-width: 1280px) {
            //opacity: 0.6;
            font-family: 'Archivo-Light';
            font-size: 12px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.42;
            letter-spacing: 0.36px;
            text-align: center;
            color: #292929;
            margin-top: 13px;
        }
        @media (min-width: 1920px) {
            font-size: 16px;
            line-height: 1.38;
            letter-spacing: 0.48px;
        }
    }
    .tooltip-cta {
        &:hover {
            text-decoration: none;
            color: $white;
        }
        @media (min-width: 768px) {
        }
        @media (min-width: 1280px) {
            width: 262px;
            height: 41px;
            border-radius: 6px;
            background-color: $black;
            opacity: 1;
            font-family: 'Archivo-Medium';
            font-size: 9px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.56;
            letter-spacing: 1.58px;
            text-align: center;
            color: $white;
            padding: 18px 0;
            display: block;
            margin-top: 18px;
            text-transform: uppercase;
        }
        @media (min-width: 1920px) {
            width: 361px;
            height: 60px;
            border-radius: 8px;
            font-size: 12px;
            line-height: 1;
            letter-spacing: 2.11px;  
            margin-top: 16px;     
            padding: 24px 0; 
        }
    }
}

.tooltip-img,
.white-tooltip {
    width: 22px;
    height: 22px;
}

@media screen and (min-width: 1920px) {
    .tooltip-img,
    .white-tooltip {
        width: 33px;
        height: 33px;
    }
}

.home-tooltip-image {
    padding-bottom: 100%;
    @media (min-width: 768px) {
        padding-bottom: 108.855%;
    }
    @media (min-width: 1280px) {
        padding-bottom: 77.189%;
    }
    @media (min-width: 1920px) {
        
    }
}

.tooltip-1, .tooltip-2, .tooltip-3 {
    display: block;
    background: url("../images/icons/hotshot.svg") center center no-repeat;
    position: absolute;
    width: 25px;
    height: 25px;
    text-indent: -9999px;
    background-size: 90%;  
    
    &.focused {
        background: url("../images/icons/hotshot-hover.svg") center center no-repeat;
        position: absolute;
        background-size: 90%;
    }
                
}

.popover-hotspot {
    max-width: none;
    //z-index: 10;
    border-radius: 6px;
    border: none;
    margin: 0 !important;

    .arrow {
        display: none;
    }

    .bs-popover-right, .bs-popover-right, .bs-popover-top, .bs-popover-bottom {
        margin: 0 !important;
    }
    .popover-body {
        @media (min-width: 1280px) {
            width: 295px;
            padding: 22px 16px 17px 17px;
            border-radius: 6px;
            background-color: $white;
            z-index: 10;
        }
        @media (min-width: 1920px) {
            width: 401px;
            border-radius: 8px;
            padding: 37px 20px 20px;
        }

        .tooltip-title, .tooltip-1-title, .tooltip-2-title, .tooltip-3-title { 
            @media (min-width: 768px) {
    
            }
            @media (min-width: 1280px) {
                font-family: 'Butler-Light';
                font-size: 24px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: center;
                color: $black;
                text-transform: uppercase;
            }
            @media (min-width: 1920px) {
                font-size: 30px;
            }
        }
    
        .tooltip-description, .tooltip-1-description, .tooltip-2-description, .tooltip-3-description {
            @media (min-width: 768px) {
    
            }
            @media (min-width: 1280px) {
                //opacity: 0.6;
                font-family: 'Archivo-Light';
                font-size: 12px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.42;
                letter-spacing: 0.36px;
                text-align: center;
                color: #292929;
                margin-top: 13px;
            }
            @media (min-width: 1920px) {
                font-size: 16px;
                line-height: 1.38;
                letter-spacing: 0.48px;
            }
        }
    
        .tooltip-cta {
            &:hover {
                text-decoration: none;
                color: $white;
            }
            @media (min-width: 768px) {
    
            }
            @media (min-width: 1280px) {
                width: 262px;
                height: 41px;
                border-radius: 6px;
                background-color: $black;
    
                opacity: 1;
                font-family: 'Archivo-Medium';
                font-size: 9px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.56;
                letter-spacing: 1.58px;
                text-align: center;
                color: $white;
                padding: 18px 0;
                display: block;
                margin-top: 18px;
                text-transform: uppercase;
            }
            @media (min-width: 1920px) {
                width: 361px;
                height: 60px;
                border-radius: 8px;
    
                font-size: 12px;
                line-height: 1;
                letter-spacing: 2.11px;  
                margin-top: 16px;     
                padding: 24px 0; 
            }
        }
    }
}