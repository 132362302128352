// shop-by-look shopByLook
.slot__container:has(.shop-by-look) {
    position: relative;
    overflow: hidden;
}

.shop-by-look__container {
    position: relative;
}

.shop-by-look__tile-wrapper {
    width: 250px;
    padding: 20px 12px;
    background: $white;
    margin: 0 11px;
    box-sizing: content-box;
    height: 100%;
}

.shop-by-look__background-img {
    width: 100%;
    // aspect-ratio: var(--shthlk-ratio, 375/352);
}

.shop-by-look__content {
    background: $shark;
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    color: $white;
    text-align: center;
    align-items: center;
}

.shop-by-look__content-info {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.shop-by-look__content-title {
    font-size: 20px;
    font-family: $didot;
    font-weight: 400;
    line-height: 1.25;
    margin: 0;
}

// might be some global styles
.shop-by-look__content-cta {
    flex: 0 0 auto;
    border-radius: 2px;
    border: 1px solid $white;
    height: 48px;
    min-width: 224px;
    font-family: $helveticaneueMd;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.45;
    letter-spacing: 0.01em;
    color: $white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 18px;
    text-decoration: none;
    text-align: center;

    &:hover,
    &:active {
        color: $shark;
        text-decoration: none;
        background-color: $white;
    }
}

.shop-by-look__body {
    font-size: 14px;
    font-family: $helveticaneue;
    font-weight: 400;
    line-height: 1.45;
    letter-spacing: 0.03em;
}

.shop-by-look__modal-button {
    position: absolute;
    top: var(--shthlk-btn-y, 0);
    left: var(--shthlk-btn-x, 0);
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    background: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: '';
        display: block;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        border: 2px solid $white;
        transform: scale(1);
        transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }

    &:focus-visible {
        outline: none;

        &::before {
            transform: scale(1.2);
        }
    }
}

.shop-by-look__modal {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .shop-by-look:not(.modal-shown) & {
        display: none;
    }

    .slick-list {
        padding: 0 !important;
    }

    .slick-track {
        display: flex;
        max-width: 100vw !important;
    }

    .slick-slide {
        width: auto !important;
        display: flex;
    }

    .slide {
        height: 100%;
    }

    .tile-body {
        margin-bottom: 0;
    }

    .price {
        .strike-through {
            display: none;
        }
    }
}

@include media-breakpoint-up(xl) {
    .shop-by-look__container {
        margin: 0;
    }
    .shop-by-look__modal-close-button {
        position: absolute;

        span {
            display: none;
        }
    }

    // .shop-by-look__background-img {
    //     aspect-ratio: var(--shthlk-ratio-lg, var(--shthlk-ratio, 1440/810));
    // }

    .shop-by-look__content {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
        padding: 128px 64px 48px 48px;
        flex-direction: row;
        align-items: center;
        gap: 28px;
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: left;
    }

    .shop-by-look__content-title {
        font-size: 32px;
    }

    .shop-by-look__body {
        font-size: 16px;
    }

    .shop-by-look__content-cta {
        margin-left: auto;
    }

    .shop-by-look__content-info {
        gap: 28px;
    }

    .shop-by-look__modal {
        top: calc(var(--center-y, 50) * 1%);
        left: calc(var(--center-x, 50) * 1%);
    }

    .shop-by-look__tile-wrapper {
        padding: 20px 20px 32px;

        &:not(:is(.selected>div)) {
            display: none;
        }
    }

    .shop-by-look__modal-button {
        top: var(--shthlk-btn-y-lg, var(--shthlk-btn-y, 0));
        left: var(--shthlk-btn-x-lg, var(--shthlk-btn-x, 0));
    }
}

@include media-breakpoint-down(lg) {
    .shop-by-look__modal {
        width: 100%;
        height: 100%;
    }

    .shop-by-look__modal-close-button {
        position: static;
        margin-bottom: 20px;
        width: 250px; // tile width
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        &::after {
            content: "\00d7";
            border-radius: 50%;
            border: 1px solid $white;
            line-height: 0;
            position: relative;
            width: 24px;
            height: 24px;
            font-size: 20px;
            line-height: 20px;
            color: $white;
            margin-right: -12px; // white space around tile
            background-color: rgba(0, 0, 0, 0.5);
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            inset: 0;
            background: $shark;
            opacity: 0.5;
        }

        span {
            color: $white;
            margin-right: 10px;
            z-index: 1;
        }
    }
}