.home__carousel {
    @include media-breakpoint-up(md) {
        margin-bottom: 8rem;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 3rem;
    }
}

    .home-category__title {
        margin-top: 4rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 3.25rem;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 1.25rem;
        }
    }

    .home-category__carousel {
        .slick-arrow {
            top: 45%;
        }

        .slick-prev {
            left: 21%;
            transform: translate(-50%, -50%);
        }

        .slick-next {
            right: 21%;
            transform: translate(50%, -50%);
        }

        &:not(.slick-initialized) {
            display: flex;
            overflow: auto;
            padding: 0 21%;

            .home-category__carousel-group {
                flex: 1 0 100%;
            }
        }
    }

        .home-category__carousel-group {
            @include media-breakpoint-up(xl) {
                margin-right: 4.0625rem;
                margin-left: 4.0625rem;
                &:hover, &:active, &:focus {
									outline: 1px dotted #ccc; 
								}
            }

            @include media-breakpoint-between(md, lg) {
                margin-right: 4.5vw;
                margin-left: 4.5vw;
            }

            @include media-breakpoint-down(sm) {
                margin-right: 1rem;
            }
        }

        .home-category__carousel-item {
            @include media-breakpoint-up(xl) {
            	.home-category__carousel-item-anchor {
                &:active, &:focus {
									outline: 1px dotted #ccc; 
								}
            	}
            }
            
            @include media-breakpoint-up(md) {
                flex: 1 1 auto;

                &:not(:last-child) {
                    margin-right: $grid-gutter-width;
                }
            }

            @include media-breakpoint-down(sm) {
                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }
        }

        .home-category__carousel-item-anchor {
            outline: none;
        }

        .home-category__carousel-item-name {
            letter-spacing: 0.4px;
            margin-top: 1rem;
        }