%heading {
    font-family: $didot;
    margin: 0;
    color: $shark;
    font-weight: 400
}

.discover-services {
    background: $cararra;
    padding: 40px 0;
    margin: 0 0 64px;
}

.discover-services__title {
    @extend %heading;
    margin-top: 48px;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 1.25;
}

.discover-services__content {
    flex: 1 1 33%;
    position: relative;
    padding: 48px 0;
    margin: 0 48px;
    gap: 28px;

    &:not(:last-child) {
        border-bottom: 1px solid $silver;
    }
}

.discover-services__content--title {
    @extend %heading;
    font-family: $helveticaneue;
    font-size: 20px;
    line-height: 1.25;
    letter-spacing: 0.02em;
}

.discover-services__content--description {
    font-family: $helveticaneue;
    font-weight: 400;
    color: $rollingstone;
    font-size: 14px;
    line-height: 1.45;
    letter-spacing: 0.03em;
}

.discover-services__content--cta {
    text-decoration: underline;
    min-height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px 32px;
    font-family: $helveticaneueMd;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.45;
    letter-spacing: 0.01em;
    color: $grey5c;

    &:hover,
    &:active {
        text-decoration: none;
    }
}

@include media-breakpoint-up(xl) {
    margin: 0 0 48px;
    .discover-services {
        $horizontal-space: 48px;
        padding: 24px #{$horizontal-space} 32px;
        max-width: #{map-get($grid-breakpoints, xl) -2 * $horizontal-space} //1200 - 2*48;

    }

    .discover-services__title {
        margin-bottom: 12px;
        font-size: 26px;
    }

    .discover-services__content {
        margin: 48px 0;
        padding: 0 64px;

        &:not(:last-child) {
            border-bottom: none;
            border-right: 1px solid $silver;
        }
    }
}