.home__2up--a {
    
	.main-2up--a {
		max-width: 1252px;
        margin: 0 auto;
        padding-top: 5rem;
        
		img {
			width: 100%;
        }
        
        h3 {
            font-size: 2.375rem;
            line-height: 0.74;
            letter-spacing: 1.4px;
            color: $darkgreen;
            margin-bottom: 4rem;

            @include media-breakpoint-down(sm) {
                font-size: 1.75rem;
            }
        }
    }
    
	.wrapper__2up {
        @include flexrow;
        flex-wrap: wrap;
            @include media-breakpoint-down(sm) {
                @include columnnormal;
                padding-left: 3rem;
                padding-right: 3rem;
            }

            @include media-breakpoint-down(xs) {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        
        
		.content__2up {
            width: 50%;
            margin-bottom: 6.25rem;

            @include media-breakpoint-down(sm) {
                width: 100%;
                margin-bottom: 0;
            }

            &--img {
                .mobile {
                    display: none;

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                .desktop {
                    display: block;
                    margin: 0 auto;
                    @include media-breakpoint-down(sm) {
                        display: block;
                    }
                }
            }  

            &--info {

                h4 {
                    letter-spacing: 0.5px;
                    line-height: 1.36;
                    font-family: $butler;
                    color: $darkgreen;
                }

                a {
                    display: block;
                    text-decoration: none;
                    font-size: 12px;
                    letter-spacing: 2px;
                    margin-top: 1.5rem;
                    font-family: $archivo;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 3.75rem;
                    }
                }
            }

            .small,
            .large {

                h4 {
                    margin-top: 2rem;
                    @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }

            }
            
            .small {
                width: 64%;
                margin: 0 auto;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin: 0;
                }
                
                img {
                    max-width: 400px;
                    max-height:400px;
                    margin-top: 6rem;

                    @include media-breakpoint-down(sm) {
                        margin-top: 0;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            
            .large {
                
                img {
                    max-width: 626px;
                    max-height: 626px;
                    @include media-breakpoint-down(sm) {
                        max-width: 100%;
                        max-height: 100%;
                    }
                    @include media-breakpoint-down(xs) {
                        width: 100%;
                        max-height: 344px;
                    }
                }
            }
		}
	}
}