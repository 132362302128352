//Fonts
@import url('https://fonts.googleapis.com/css?family=Archivo');
@import url('https://fonts.googleapis.com/css?family=PT+Serif:400,700');

@font-face {
    font-family: 'Didot';
    src: url('../fonts/Didot/DidoteTextPro-Regular.otf') format("opentype");
}
@font-face {
    font-family: 'Didot';
    font-weight: bold;
    src: url('../fonts/Didot/DidoteTextPro-Bold.otf') format("opentype");
}
@font-face {
    font-family: 'Didot';
    font-style: italic;
    src: url('../fonts/Didot/DidoteTextPro-Italic.otf') format("opentype");
}
@font-face {
    font-family: 'Didot';
    font-weight: bold;
    font-style: italic;
    src: url('../fonts/Didot/DidoteTextPro-BoldItalic.otf') format("opentype");
}
@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/HelveticaNeue/HelveticaNeueeTextPro-Roman.otf') format("opentype");
}
@font-face {
    font-family: 'HelveticaNeueMd';
    src: url('../fonts/HelveticaNeue/HelveticaNeueeTextPro-Md.otf') format("opentype");
}

$didot: 'Didot';
$helveticaneue: 'HelveticaNeue';
$helveticaneueMd: 'HelveticaNeueMd';

/*Replace old fonts with new fonts by default*/
// $archivo:  $helveticaneue;
// $butler: $didot;


// @font-face {
//     font-family: 'Butler-Black';
//     font-weight: 900;
//     src: url('../fonts/Didot/DidoteTextPro-Bold.otf') format("opentype");
// }
// @font-face {
//     font-family: 'Butler-Bold';
//     font-weight: 700;
//     src: url('../fonts/Didot/DidoteTextPro-Bold.otf') format("opentype");
// }
// @font-face {
//     font-family: 'Butler-Extrabold';
//     font-weight: 800;
//     src: url('../fonts/Didot/DidoteTextPro-Bold.otf') format("opentype");
// }
// @font-face {
//     font-family: 'Butler-Light';
//     font-weight: 300;
//     src: url('../fonts/Didot/DidoteTextPro-Regular.otf') format("opentype");
// }
// @font-face {
//     font-family: 'Butler-Medium';
//     font-weight: 500;
//     src: url('../fonts/Didot/DidoteTextPro-Regular.otf') format("opentype");
// }
// @font-face {
//     font-family: 'Butler-Regular';
//     font-weight: 400;
//     src: url('../fonts/Didot/DidoteTextPro-Regular.otf') format("opentype");
// }
// @font-face {
//     font-family: 'Butler-UltraLight';
//     font-weight: 200;
//     src: url('../fonts/Didot/DidoteTextPro-Regular.otf') format("opentype");
// }

// @font-face {
//     font-family: 'Archivo-Bold';
//     font-weight: 700;
//     src: url('../fonts/HelveticaNeue/HelveticaNeueeTextPro-Roman.otf') format("opentype");
// }
// @font-face {
//     font-family: 'Archivo-ExtraBold';
//     font-weight: 800;
//     src: url('../fonts/HelveticaNeue/HelveticaNeueeTextPro-Roman.otf') format("opentype");
// }
// @font-face {
//     font-family: 'Archivo-ExtraLight';
//     font-weight: 200;
//     src: url('../fonts/HelveticaNeue/HelveticaNeueeTextPro-Roman.otf') format("opentype");
// }
// @font-face {
//     font-family: 'Archivo-Light';
//     font-weight: 300;
//     src: url('../fonts/HelveticaNeue/HelveticaNeueeTextPro-Roman.otf') format("opentype");
// }
// @font-face {
//     font-family: 'Archivo-Medium';
//     font-weight: 500;
//     src: url('../fonts/HelveticaNeue/HelveticaNeueeTextPro-Roman.otf') format("opentype");
// }
// @font-face {
//     font-family: 'Archivo-Regular';
//     font-weight: 400;
//     src: url('../fonts/HelveticaNeue/HelveticaNeueeTextPro-Roman.otf') format("opentype");
// }
// @font-face {
//     font-family: 'Archivo-SemiBold';
//     font-weight: 600;
//     src: url('../fonts/HelveticaNeue/HelveticaNeueeTextPro-Roman.otf') format("opentype");
// }
// @font-face {
//     font-family: 'Archivo-Thin';
//     font-weight: 100;
//     src: url('../fonts/HelveticaNeue/HelveticaNeueeTextPro-Roman.otf') format("opentype");
// }

$ptSerif: 'PT Serif', serif;

// font name/
$archivo:  "Archivo"; // replaced with Helvetica Neue
$butler: "Butler"; // replaced with Didot

$butlerBlack:'Butler-Black';
$butlerBold: 'Butler-Bold';
$butlerExtraBold:'Butler-Extrabold';
$butlerLight:'Butler-Light';
$butlerMedium:'Butler-Medium';
$butlerRegular:'Butler-Regular';
$butlerExtraLight:'Butler-UltraLight';

$archivoBold:'Archivo-Bold';
$archivoExtraBold:'Archivo-ExtraBold';
$archivoExtraLight:'Archivo-ExtraLight';
$archivoLight:'Archivo-Light';
$archivoMedium:'Archivo-Medium';
$archivoRegular:'Archivo-Regular';
$archivoSemiBold:'Archivo-SemiBold';
$archivoThin:'Archivo-Thin';


// enable back old fonts
// $archivo:  $helveticaneue;
// $butler: $didot;
$didot: $butler;
$helveticaneue: $archivo;
$helveticaneueMd: $archivo;

body {
    margin: 0;
}
