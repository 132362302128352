.slot__container:has(.scrollable-list) {
    background: $cararra;
    padding: 40px 0 32px;
    margin-top: 0;

    .slot__info {
        background: transparent;
    }
}

.scrollable-list__items {
    @include custom-scroll();

    padding: 0 16px 32px;
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    overflow: auto;

    &::-webkit-scrollbar-track {
        margin: 9px 16px 9px 16px;
    }
}

.scrollable-list {
    position: relative;

    .scrollable-nav {
        display: none; // default state
        background: $cararra;
        padding: 0 16px;
        flex-direction: row;
        gap: 4px;
        flex-wrap: nowrap;
        align-items: center;
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateY(calc(50% - 9px/2)); // - center with scrollbar
    }

    .scrollable-nav__prev,
    .scrollable-nav__next {
        font-size: 0px;

        &[disabled] {
            opacity: .25;
        }

        &::before {
            content: $svg-arrow-left;
            display: block;
            height: 24px;
            width: 24px;
        }
    }

    .scrollable-nav__next::before {
        transform: scaleX(-1)
    }
}

.scrollable-list-item__media-wrapper {
    aspect-ratio: 1;
    margin-bottom: 12px;
    position: relative;
    flex: 0 0 auto;
    width: 100%;
    background-color: $white;
}

.scrollable-list-item__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.scrollable-list-item {
    width: 284px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: flex-start;
}


.scrollable-list-item__title {
    font-family: $helveticaneue;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.45;
    letter-spacing: 0.03em;
    text-align: center;
    color: $shark;

    .scrollable-list-item:hover &,
    .scrollable-list-item:active & {
        text-decoration: underline;
    }
}

@include media-breakpoint-up(xl) {
    .slot__container:has(.scrollable-list) {
        margin-top: 0;
    }

    .scrollable-list-item {
        width: 318px;
    }

    .scrollable-list {
        &:not(:has(.scrollable-nav__prev:disabled):has(.scrollable-nav__next:disabled)) {
            --show-scroll-nav: 1;

            .scrollable-nav {
                display: flex;
            }

            .scrollable-list__items {
                &::-webkit-scrollbar-track {
                    margin-right: #{76px + 16px};
                }
            }
        }
    }
}