.home__frette--about {
    @include media-breakpoint-up(md) {
        margin-top: 6.25rem;
    }

    @include media-breakpoint-down(xs) {
        margin-top: 2rem;
    }
}

.frette__about {
    max-width: 1440px;
    margin: 0 auto 5rem;

    .container--about-top {
        align-items: center;
    }

    .container--about {

        &-top {
            display: flex;
        }

        &-bottom {
            @include flexrow;
            max-width: 1022px;
            margin: 5.5rem auto 0;

            @include media-breakpoint-down(xs) {
                @include columnnormal;
                padding-top: 3rem;
            }

            .about-bottom__info {
                width: 50%;
                padding-top: 1rem;
                padding-bottom: 1.5rem;

                @include media-breakpoint-down(lg) {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin-bottom: 3.125rem;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }

                &:first-child {
                    border-right: solid 1px $grey10;

                    @include media-breakpoint-down(xs) {
                        border: none;
                    }
                }

                &:last-child {
                    padding-left: 7rem;

                    @include media-breakpoint-down(xs) {
                        padding-left: 1rem;
                    }
                }
            }

            .about-bottom__info-title {
                line-height: 1.42;
                margin-bottom: 1.5rem;
            }
        }
    }

    .left {
        flex-direction: row;

            @include media-breakpoint-down(xs) {
                flex-direction: column;
            }
    }

    .right {
        flex-direction: row-reverse;

        @include media-breakpoint-down(lg) {
            flex-direction: column-reverse;
        }
    }

    &--img {
        max-width: 58.375rem;
        margin: 0 auto;

        .mobile {
            display: none;
        }

        @include media-breakpoint-down(xs) {
            .desktop {
                display: none;
            }

            .mobile {
                display: block;
            }
        }

        img {
            width: 100%;
            height: 100%;

            @include media-breakpoint-down(xs) {
                width: 100%;
                height: 258px;
            }
        }
    }

    &--info {
        max-width: 22.75rem;
        margin: auto;
        
        @include media-breakpoint-down(lg) {
        		padding: 0 1rem;
        }
        
        @include media-breakpoint-down(sm) {
            padding: 0 1rem;
            width: 100%;
        }
        @include media-breakpoint-down(xs) {
            padding: 0 1rem;
            margin-top: 1.875rem;
            width: 100%;
        }

        .info--box {
            border: solid 6px $black;
            height: 100%;
            padding-left: 3.75rem;
            padding-right: 3.75rem;
            padding-bottom: 4.5rem;
            text-align: left;

						@include media-breakpoint-down(lg) {
                padding-left: 1rem;
                padding-right: 1rem;
                padding-bottom: 1rem;
						}
						
            @include media-breakpoint-down(sm) {
                padding-left: 1rem;
                padding-right: 1rem;
                padding-bottom: 1rem;
                text-align: center;
            }
            @include media-breakpoint-down(xs) {
                padding-left: 2.625rem;
                padding-right: 2.625rem;
                text-align: center;
            }

            @include media-breakpoint-down(xs) {
                padding-bottom: 0;
            }

            h2 {
                line-height: 1.2;
                letter-spacing: 0.5px;
                margin-top: 4.5rem;
                font-family: $butler;
                color: $darkgreen;
                
                @include media-breakpoint-down(lg) {
                		margin-top: 1.2rem;
                }
                
                @include media-breakpoint-down(sm) {
                		margin-top: 1.2rem;
                }

                @include media-breakpoint-down(xs) {
                    margin-top: 2.75rem;
                    font-size: 2.5rem;
                }
            }

            p{
                line-height: 1.71;
                letter-spacing: 0.5px;
                color: $matterhorn;
                font-size: 0.875rem;
                margin-top: 1rem;
                margin-bottom: 2rem;
                font-family: $archivo;
            }

            a {
                @include media-breakpoint-down(xs) {
                    margin-bottom: 3.25rem;
                }
            }
        }
    }
}
