.home__section-5 {
    margin: 0 0 0 20px;
    padding: 0 0 64px 0;

    @media (min-width: 768px) {
        border-top: 1px solid #eaeaea;
        padding: 50.5px 0;
        margin: 0 20px;
        position: relative;
    }
    @media (min-width: 1280px) {
        padding: 51.5px 0;
        margin: 0 26px;
    }
    @media (min-width: 1920px) {
        padding: 75.5px 0;
        margin: 0 41px;
    }
}

.home-tooltip-swatch__carousel {
    .slick-prev {
        @media (min-width: 1280px) {
            top: 47.3%;
            color: $white;
            left: 29px;
        }
        @media (min-width: 1920px) {
            width: 22px;
            height: 41px;
            left: 44px;
        }
        &::before {
            display: none;
        }
        background: url('../images/icons/left-arrow-icon.svg') center center no-repeat;
        background-size: cover;
        width: 15px;
        height: 27px;
        opacity: 1;
    }

    .slick-next {
        @media (min-width: 1280px) {
            top: 47.3%;
            color: $white;
            right: 29px;
        }
        @media (min-width: 1920px) {
            width: 22px;
            height: 41px;   
            right: 44px;
        }
        &::before {
            display: none;
        }
        background: url('../images/icons/right-arrow-icon.svg') center center no-repeat;
        background-size: cover;
        width: 15px;
        height: 27px;
        opacity: 1;   
    }
}

.home-tooltip-swatch {
    z-index: 1;
    @media (min-width: 768px) {
        position: relative;
       
    }

    img {
        border-radius: 8px;
        @media (min-width: 768px) {
            border-radius: 6px;
        }
        @media (min-width: 1280px) {
            border-radius: 6px;
        }
        @media (min-width: 1920px) {
            border-radius: 8px;
        }
    }
}

.home-tooltip-swatch__thumbs {
    @media (max-width: 767.98px) {
        //display: none !important;
        max-width: 100% ;
        
    }
    .slick-list {
        @media (max-width: 767.98px) {
            padding: 0 47px 0 0 !important;
        }
    }

    .home-tooltip-swatch__carousel-item  {
        @media (max-width: 767.98px) {
            padding-right: 11px;
        }
    }

    @media (max-width: 767.98px) {
        .slick-prev {
            display: none;
        }

        .slick-next  {
            position: absolute;
            top: calc((100% - 27px)/2);
            right: 15px;
            left: auto;
            &::before {
                display: none;
            }
            background: url('../images/icons/slider-right-arrow.svg') center center no-repeat;
            background-size: cover;
            width: 15px;
            height: 27px;
        
        }
    }
}

.home-tooltip-swatch__info {
    max-width: 100%;
    margin: auto;
    @media (min-width: 768px) {
        position: absolute;
        top: 72px;
    }
    @media (min-width: 1280px) {
        top: 84px;

    }
    @media (min-width: 1920px) {
        top: 126px;
    }
}

.home-tooltip-swatch__carousel {
    @media (max-width: 767.98px) {
        max-width: 100% ;
        display: none;
    }

    .slick-list {
        @media (max-width: 767.98px) {
            padding: 0 47px 0 0 !important;
        }
    }

    .slick-dots {
        display: flex;
        margin-bottom: 0;  
        bottom: -20px;          
        @media (max-width: 1279.98px) {
            display: none !important;
        }
        button {
            &:after {
                width: 7px;
                height: 7px;
                opacity: 1;
                background: #757575;
                @media (min-width: 1920px) {
                    width: 9px;
                    height: 9px;
                }
            }
        }
        .slick-active {
            button {
                &:after {
                    background: #000000;
                }
            } 
        }
    }
}

.home-tooltip-image__carousel-tooltip {
    @media (max-width: 1279.98px) {
        display: none;
    }
    .tooltip-1, .tooltip-2, .tooltip-3 {
        z-index: 2;
        &.z-index {
            z-index: 1;
        }
    }
  
    .tooltip-img {
        display: block;
 
    }
    .largetooltip-img {
        display: none;
        @media (min-width: 1920px) {
            display:block;
        }  
    }

    .white-tooltip {
        display: none !important;
        position: absolute;
        left: 0px;
        top: 1px;
    }

    .white-tooltip-large {
        display: none !important;
        position: absolute;
        left: 0px;
        top: 0px;
    }

      
    .tooltip-1, .tooltip-2, .tooltip-3 {
        .tooltip-content {
            display: none;
        }
    }
    .tooltip-1, .tooltip-2, .tooltip-3 {
         &:hover, &:focus {
            cursor: pointer;
        
            >.white-tooltip {
                @media (min-width: 1280px)  {
                    display: inline !important;
                }  
            }

            >.tooltip-img {
                @media (min-width: 1280px) {
                    visibility: hidden;
                } 
            }
        }
    }

    .tooltip-img {
        &.hide {
            visibility: hidden;
        }
        &.show {
            display: block;
        }
    }

    .white-tooltip {
        &.hide {
            display: none !important;
        }
        &.show {
            display: inline !important;
        }
    }
    
    .tooltip-1-image, .tooltip-2-image, .tooltip-3-image {
        z-index: 1;
        position: relative;
    }

    .tooltip-1-content, .tooltip-2-content, .tooltip-3-content {
        @media (min-width: 1280px) {
            width: 295px;
            padding: 22px 16px 17px 17px;
            border-radius: 6px;
            background-color: $white;

            z-index: 10;
        }
        @media (min-width: 1920px) {
            width: 401px;
            border-radius: 8px;
            padding: 37px 20px 20px;
        }
    }

    .tooltip-1-content {
        @media (min-width: 1280px) {
            bottom: -35px;
            left: 22px;
        }
        @media (min-width: 1920px) {
            left: 41px;
        }
    }

    .tooltip-3-content {
        @media (min-width: 1280px) {
            right: -140px;
            bottom: 24px;
        }
        @media (min-width: 1920px) {
            bottom: 35px;
        }
    }

    .tooltip-2-content {
        @media (min-width: 1280px) {
            right: -140px;
            bottom: 24px;
        }
        @media (min-width: 1920px) {
            bottom: 35px;
        }
    }
    .tooltip-1-title, .tooltip-2-title, .tooltip-3-title {
        @media (min-width: 768px) {

        }
        @media (min-width: 1280px) {
            font-family: 'Butler-Light';
            font-size: 24px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: $black;
            text-transform: uppercase;
        }
        @media (min-width: 1920px) {
            font-size: 30px;
        }
    }

    .tooltip-1-description, .tooltip-2-description, .tooltip-3-description {
        @media (min-width: 768px) {

        }
        @media (min-width: 1280px) {
            //opacity: 0.6;
            font-family: 'Archivo-Light';
            font-size: 12px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.42;
            letter-spacing: 0.36px;
            text-align: center;
            color: #292929;
            margin-top: 13px;
        }
        @media (min-width: 1920px) {
            font-size: 16px;
            line-height: 1.38;
            letter-spacing: 0.48px;
        }
    }

    .tooltip-cta, .tooltip-1-cta, .tooltip-2-cta, .tooltip-3-cta {
        &:hover {
            text-decoration: none;
        }
        @media (min-width: 768px) {

        }
        @media (min-width: 1280px) {
            width: 262px;
            height: 41px;
            border-radius: 6px;
            background-color: $black;

            opacity: 1;
            font-family: 'Archivo-Medium';
            font-size: 9px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.56;
            letter-spacing: 1.58px;
            text-align: center;
            color: $white;
            padding: 17px 0;
            display: block;
            margin-top: 18px;
            text-transform: uppercase;
        }
        @media (min-width: 1920px) {
            width: 361px;
            height: 60px;
            border-radius: 8px;

            font-size: 12px;
            line-height: 1;
            letter-spacing: 2.11px;  
            margin-top: 16px;    
            padding: 24px 0;  
        }
    }
}
 
.home-tooltip-image__carousel-item  {
    @media (max-width: 767.98px) {
        padding-right: 11px;
    }
}

.home-tooltip-swatch__carousel-item {
    @media (min-width: 768px) {
        cursor: pointer;
        padding-right: 9px;
    }
    @media (min-width: 1280px) {
        padding-right: 16px;
    }
    @media (min-width: 1920px) {
        padding-right: 24px;
    }

    &:last-child {
        @media (min-width: 1280px) {
            padding-right: 18px;
        }
        @media (min-width: 1920px) {
            padding-right: 27px;
        }
    }

    img {
        border-radius: 8px;
        @media (min-width: 768px) {
            transition: all .3s cubic-bezier(0,0,.5,1);
            border-radius: 6px;
        }
        @media (min-width: 1280px) {

        }
        @media (min-width: 1920px) {
            border-radius: 8px;
        }
        &:hover {
            @media (min-width: 1280px) {
                cursor: pointer;
                box-shadow:2px 4px 16px rgba(0,0,0,0.16);
                transform: scale3d(1.01,1.01,1.01);
            }
        }
    }
}

.home-shop-collection__info {
        z-index: 10;
    @media (min-width: 768px) {
        text-align: center;
        position: absolute;
        width: 50%;
        right: 0;
        top: 122px;
    }
    @media (min-width: 1280px) {
        top: 135px;
        right: auto;
    }
    @media (min-width: 1920px) {
        top: 201px;
    }
}

.home-tooltip-swatch__content--title {
    font-family: 'Butler-Light';
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 17px;

    @media (min-width: 768px) {
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 18px;
    }
    @media (min-width: 1280px) {
        
    }
    @media (min-width: 1920px) {
        font-size: 30px;
        line-height: 1;
        margin-bottom: 44px;
    }
}
.home-tooltip-swatch__content--description {
    //opacity: 0.6;
    font-family: 'Archivo-ExtraLight';
    font-size: 16px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.48px;
    text-align: center;
    color: #292929;
    margin-bottom: 28.5px;
    padding: 0 40px;

    @media (min-width: 768px) {
        font-family: 'Archivo-Light';
        font-size: 12px;
        font-weight: 300;
        line-height: 1.42;
        letter-spacing: 0.36px;
        margin: 0 auto 17px;
        width: 239px;
        padding: 0;
    }

    @media (min-width: 1920px) {
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 0.48px;
        margin: 0 auto 34px;
        width: 359px;
    }
}

.home-tooltip-swatch__content--cta {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    background-color: $black;

    font-family: 'Archivo-Medium';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.11px;
    text-align: center;
    color: $white;
    padding: 24px 0;
    display: block;
    text-transform: uppercase;
    margin: auto;
    &:hover {
        text-decoration: none;
    }
    &:focus{
        outline: 2px solid $blue;
    }

    @media (min-width: 768px) {
        min-width: 169px;
        width: auto;
        height: 41px;  
        border-radius: 6px;

        opacity: 1;
        font-size: 9px;
        line-height: 0.56;
        letter-spacing: 1.58px;
        padding: 19px 15px;
        display: inline-block;
    }

    @media (min-width: 1920px) {
        min-width: 225px;
        height: 60px;
        border-radius: 8px;

        font-size: 12px;
        line-height: 1;
        letter-spacing: 2.11px;
        padding: 24px 22px;
    }

    &:hover {
        color: $white;
    }
}

.home-shop-collection__info {
    @media (max-width: 767.98px) {
        border-top: 1px solid #eaeaea;
        margin-right: 20px;
        padding: 64px 0 64.5px;
    }
}

@media (min-width: 768px) and (max-width: 1279.98px){
    .home-tooltip-section {
        flex-direction: row-reverse;
    }

    .home-tooltip-swatch__carousel-item {
        padding-left: 9px;
        padding-right: 0;
    }
}

.home-tooltip-image-carousel {
        padding-bottom: 85.86%;
        @media (min-width: 768px) {
            padding-bottom: 105.495%;
        }
        @media (min-width: 1280px) {
            padding-bottom: 75.245%;
        }
        @media (min-width: 1920px) {
            padding-bottom: 75.3%;
        }
    }
