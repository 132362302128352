
.up-content__container {
    position: relative;
    max-width: 84.75rem;
    margin: 5.125rem auto;

    @include media-breakpoint-up(md) {
        padding-top: 5.5rem;
        padding-bottom: 7rem;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 3.25rem;
        padding-bottom: 4.75rem;
        padding-left: 1rem;
    }
}

    .up-content__center {
        max-width: 78.25rem;
        margin: 0 auto;
    }

    .up-content__title {
        margin-bottom: 2.75rem;
    }


    //These need separate side-padding because the __copy containers
    //need to be center-aligned within the columns.
    .up-content__title,
    .up-content__copy {
        @include media-breakpoint-up(lg) {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }

        @include media-breakpoint-between(md, lg) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }

    .up-content__list {
        &:not(.slick-initialized) {
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;

            .up-content__item {
                flex: 1 0 80%;
            }
        }
    }

    .up-content__item {
        @include media-breakpoint-down(sm) {
            margin-right: 2.75rem;
            //Resets padding when initialized as a carousel.
            padding: 0;
        }
    }

    .up-content__copy-description {
        margin-top: 1.5rem;
        margin-bottom: 0;
        &.text-color--matterhorn {
            color: #505050;
        }
    }

    .numerical--sign {
        font-family: $ptSerif;
    }