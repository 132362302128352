.frette-loves-design__section {
    margin: 0 20px;
    padding: 40px 0 64px;

    @include media-breakpoint-up(xl) {
        padding: 40px 0;
    }
}

.frette-loves-design__title {
    font-family: 'Archivo-ExtraLight';
    font-size: 20px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: 3px;
    text-align: center;
    color: #000;

    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 1.94;
        letter-spacing: 2.4px;
    }
    @media (min-width: 1920px) {
        font-size: 20px;
        line-height: 1.55;
        letter-spacing: 3px;
    }
}