.home__2up--b {
    max-width: 1360px;
    width: 100%;
    margin: 0 auto;    

    @media (max-width: 1024px) {
        width: 100%;
    }

    @include media-breakpoint-down(xs) {
        .featured-hotel__paragraph {
            display: none;
        }
    }

    .main-2up--b {
        margin-bottom: 6rem;

         @include media-breakpoint-down(sm) {
            margin-bottom: 2rem;
         }
    
        .wrapper__2up--b {
            width: 100%;
            display: flex;
            align-items: center;

                @include media-breakpoint-down(sm) {
                    display: block;
                    height: 100%;
                }
        }

        .left {
            flex-direction: row;
        }

        .right {
            flex-direction: row-reverse;
        }
    }

    img {
        height: auto;

            @include media-breakpoint-down(xs) {
                max-height: 345px;
                width: 100%;
                padding-left: 1rem;
                padding-right: 1rem;
            }
    }
    
}

.content--img {
    width: 50%;

    @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 680px;
        margin: 0 auto;
    }
    
    @include media-breakpoint-between(sm, md) {
        max-width: 100%;
    }

    .mobile {
        display: none;
    }

    @include media-breakpoint-down(xs) {
        .mobile {
            display: block;
        }

        .desktop {
            display: none;
        }
    }
}


.content--info {
    width: 50%;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding-left: 9.375rem;
        padding-right: 9.375rem;
    }

    @include media-breakpoint-between(md, md) {
        padding-left: 4.25rem;
        padding-right: 4.25rem;
    }

    @include media-breakpoint-down(sm) {
        position: static;
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 3.75rem;
    }
    
    h2 {
    		font-weight: bolder;
    }
    p {
        color: #757575;
    }
}

.content--alignment {
    @include media-breakpoint-down(sm) {
        width: 100%;
        transform: none;
        margin: 1.75rem 0 2rem 0;
    }
}
.featured-hotel__img-grid-heading {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: block;
    font-weight: normal;
}

.featured-hotel__paragraph {
    margin-bottom: 1.5rem;
}

.hotel-partner__no-line {
    .line-animation {
        display: none;
    }
}