.inspiration-block {
    overflow: hidden;
    position: relative;
}

.inspiration__content-body {
    font-family: $helveticaneue;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.45;
    letter-spacing: 0.03em;
}

.inspiration__video-block {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.inspiration__content-heading {
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: nowrap;
    align-items: center;
}

.inspiration__content-title {
    font-family: $didot;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    margin: 0;
}

.inspiration__content-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 48px 32px;
    transition: all 0.3s;
    overflow: hidden;
}

.inspiration__media-wrapper {
    position: relative;
}

.inspiration__content-dark {
    --content-color: #{$white};
    --content-bg: #{$shark};
}

// might be some global styles
.inspiration__button {
    flex: 0 0 auto;
    height: 48px;
    min-width: 224px;
    font-family: $helveticaneueMd;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.45;
    letter-spacing: 0.01em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 18px;
    text-decoration: none;
    text-align: center;
    border-radius: 2px;
    border: 1px solid var(--content-color, $shark);
    color: var(--content-color, $shark);
    background-color: transparent;
    padding: 0 48px;
    transition: all 0.3s;

    &:hover,
    &:active {
        text-decoration: none;
        opacity: 0.8;
    }
}

.pseudo-icon {
    border-radius: 50%;
    border: 1px solid $white;
    padding: 4px;
    width: 32px;
    height: 32px;
    display: inline-flex;
    position: relative;
    transition: all 0.3s;
    background: var(--pseudo-icon-bg, transparent);

    &::before,
    &::after {
        content: '';
        display: block;
        width: 13px;
        height: 1.6px;
        background-color: var(--pseudo-icon-color, $white);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(var(--icon-rotation, 0));
    }

    .inspiration_show-more & {
        --icon-rotation: 90deg;
    }
}

button:has(.pseudo-icon) {
    &:active,
    &:hover {
        --pseudo-icon-bg: #{$white};
        --pseudo-icon-color: #{$shark};
    }
}

.inspiration_show-less {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    font-family: $helveticaneue;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.45;
    letter-spacing: 0.03em;
    position: absolute;
    top: calc(100% - 48px);
    transform: translateY(-100%);
    right: 64px;
}

.inspiration__content-collapse {
    display: flex;
    flex-direction: column;
    gap: 48px;
    transition: all 0.5s;

    >:first-child {
        margin-top: 24px;
    }
}

// if we need to have it clickable/hoverable full block
.inspiration_show-more {
    &:before {
        content: "";
        display: block;
        position: absolute;
        inset: 0;
    }
}

.inspiration_show-less,
.inspiration_show-more {
    transition: all 0.3s;
}

// .inspiration_show-more {
//     transition: opacity 0.3s, pointer-events 0.3s 3s;
// }

@include media-breakpoint-up(xl) {
    .inspiration__content-title {
        font-size: 26px;
    }

    .inspiration__content-body {
        font-size: 16px;
    }

    .inspiration__button {
        border-color: $white;
        background-color: $white;
        color: $shark;
        margin-right: auto;

        &:hover,
        &:active {
            text-decoration: none;
            opacity: 0.8;
            border-color: $white;
            background-color: rgba($white, 0.8);
            color: $shark;
        }
    }

    .inspiration__content-wrapper {
        $vertical-space: 48px;

        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 480px;
        color: $white;
        transition: all 0.3s;
        overflow: hidden;
        justify-content: flex-end;
        padding: 0;
        display: block;
        &:has(.inspiration__content-collapse.show),
        &:has(.inspiration__content-collapse.collapsing):has(.inspiration_show-more[aria-expanded="true"]) {
            .inspiration_show-more {
                opacity: 0;
                pointer-events: none;
            }
        }

        &:not(:where(:has(.inspiration__content-collapse.show),:has(.inspiration__content-collapse.collapsing):has(.inspiration_show-more[aria-expanded="true"]))) {
            .inspiration_show-less {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    .inspiration__content {
        position: relative;
        top: 100%;
        transform: translateY(-100%);
        padding: 48px 64px;
        z-index: 0;
        transition: all 1s;
        &:has(.inspiration__content-collapse.show),
        &:has(.inspiration__content-collapse.collapsing):has(.inspiration_show-more[aria-expanded="true"]) {
            top: 50%;
            transform: translateY(-50%);

            &::before {
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &::before {
            content: '';
            display: block;
            inset: 0;
            z-index: -1;
            position: absolute;
            background: linear-gradient(rgba(33, 37, 41, 0.50) 0%, rgba(33, 37, 41, 0.50) 100%), rgba(0, 0, 0, 0.10);
            background-blend-mode: overlay, normal;
            height: 150vh;
            pointer-events: none;
            top: 0;
            transform: translateY(0);
            transition: all 1s;
        }
    }

    .inspiration__content-collapse {
        >:first-child {
            margin-top: 36px;
        }
    }
}
// mobile view
@include media-breakpoint-down(lg) {
    .inspiration__content-wrapper {
        // text-align: center;
        background: var(--content-bg, $cararra);
        color: var(--content-color, $shark);
    }

    .inspiration__content-collapse {
        display: flex !important;
    }
}