.slot__container:has(.hero-redesign) {
    background: $cararra;
    margin: 0;

    .slot__info {
        background: #E9E1CF;
        padding: 48px 24px;
        flex-direction: column;
        align-items: stretch;
        order: 0;
    }

    .slot__text {
        font-size: 16px;
        letter-spacing: 0.03em;
    }

    .slot__info-inner {
        gap: 40px;
    }

    .slot__cta {
        padding: 0 32px;
        font-family: $helveticaneueMd;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.45;
        letter-spacing: 0.01em;
        height: 40px;
        border-radius: 2px;
        background: $shark;
        color: $white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        text-decoration: none;
    }
}

.hero-redesign {
    display: block;
    position: relative;
    overflow: hidden;

    .hero__background-img {
        @include media-breakpoint-up(xl) {
            max-height: 80vh;
            object-fit: cover;
        }
    }

    .hero__video-block {
        video {
            @include media-breakpoint-up(xl) {
                max-height: 80vh;
            }
        }
    }
}


.hero__video-block {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    &:first-child {
        position: relative;
        height: auto;

        video {
            width: 100%;
            position: relative;
        }
    }
}

.hero__content {
    position: absolute;
    inset: auto 0 0;
    padding: 96px 24px 24px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
    min-height: 275px;
    color: $white;
}

.hero__content {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.hero__content-subtitle {
    font-family: $helveticaneueMd;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.45;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin: 0;
}

.hero__content-heading {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.hero__content-title {
    font-family: $didot;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.25;
    margin: 0;
    letter-spacing: 0;
}

.hero__button {
    font-family: $helveticaneue;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.45;
    letter-spacing: 0.03em;
    text-decoration: underline;
    padding: 0;
    border: 0;
    background: transparent;
    flex: 0 0 auto;
    display: inline-flex;

    a:is(:hover, :focus, :active) & {
        text-decoration: underline;
    }
}

@include media-breakpoint-up(xl) {
    .slot__container:has(.hero-redesign) {
        margin: 0;

        .slot__info {
            padding: 48px 64px 48px 48px;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            gap: 96px;
        }

        .slot__text {
            font-size: 18px;
            letter-spacing: 0.02em;
        }
    }
    .hero__content {
        min-height: 344px;
        padding: 40px 48px;
        gap: 24px;
        justify-content: flex-end;
    }

    .hero__content-subtitle {
        font-size: 16px;
        letter-spacing: 0.03em;
    }

    .hero__content-heading {
        flex-direction: row;
        align-items: center;
        gap: 48px;
    }
    .hero__content-title {
        font-size: 48px;
    }
}