
// GRID

@mixin grid($cols, $mgn) {
 float: left;
 margin-right: $mgn;
 margin-bottom: $mgn;
 width: ((100% - (($cols - 1) * $mgn)) / $cols);
 &:nth-child(#{$cols}n) {
   margin-right: 0;
 }
}

@mixin set-visible(){
  opacity: 1;
  visibility: inherit;
}

@mixin set-invisible(){
  opacity: 0;
  visibility: hidden;
}


/**
 * [Adds styles to allow an element's height scale proportionatelly]
 * @param  {[Number]} $width
 * @param  {[Number]} $height
 */
@mixin aspect-ratio($w, $h, $usePseudo: false, $adjust: 0px) {

  $calcH:   aspect-ratio-height($w, $h);
  $finalH:  if( $adjust != 0px, calc( #{$calcH} + #{$adjust} ), $calcH );

  @if ($usePseudo) {
    position: relative;

    &:before {
      display: block;
      content: '';
      padding-bottom: $finalH;
      width: 100%;
    }
  }

  @else {
    height: 0;
    padding-bottom: $finalH;
  }
}

/**
 * [Calculates the percentage aspect ratio (what % height is compared to the width)]
 * @param  {[Number]} $width
 * @param  {[Number]} $height
 * @return {[Number(%)]}
 */
@function aspect-ratio-height($width, $height){
  @return percentage($height / $width);
}

/**
 * *****REMEMBER TO USE MARGIN OR PADDING AND NOT TOP/BOTTOM******
 * [Calculates the center of an element in relation to a provided width and height.
 * Useful to place an absolute element at the center of another when
 * the positioned element cannot be the target's child ]
 * @param  {[Number]} $width
 * @param  {[Number]} $height
 * @return {[Number(%)]}
 */
@function center-from-width($width, $height){
  @return aspect-ratio-height($width, $height) / 2;
}

/**
 * [In the  awful case you can't use Flexbox to vertically align something]
 * @param  {[type]} $transform:       false         [Use transforms to align instead of the absolute trick]
 * @param  {[type]} $pos:             absolute      [position type]
 * @param  {[type]} $posAdj:          0             [position adjustment. If $transform is true, this value only affects the 'top' property.]
 * @param  {[type]} $alignHorizontal: false         [Includes horizontal alignment]
 */
@mixin vertical-align($alignHorizontal: false, $transform: false, $pos: absolute, $posAdj: 0px){

  @if ($transform) {
    position: $pos;
    top: calc(50% + #{$posAdj});

    @if ($alignHorizontal) {
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    @else {
      transform: translate3d(0, -50%, 0);
    }
  }

  @else {
    position: absolute;
    top: $posAdj;
    bottom: $posAdj;

    @if ($alignHorizontal) {
      left: $posAdj;
      right: $posAdj;
      margin: auto;
    }

    @else {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}


// animation

@keyframes flipdown
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
    }
  5%{
    opacity: 1;
    }
  80%   {
    transform: rotateX(8deg);
    }
  83%   {
    transform: rotateX(6deg);
    }
  92%   {
    transform: rotateX(-3deg);
    }
  100%  {
    transform-origin: top center;
    transform: rotateX(0deg);
    }

//FLEX

@mixin columnreverse {
    display: flex;
    flex-direction: column-reverse;
}

@mixin columnnormal {
    display: flex;
    flex-direction: column;
}

@mixin flexrow {
    display: flex;
    flex-direction: row;
}

/**
 * [Strip the pesky units from values]
 * @param  {[Number]} $value
 */
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

/**
 * [Fluid Type]
 */
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

/// A small function allowing skipping base64 encoding
/// and simply pasting the SVG markup right in the CSS.
/// @author Jakob Eriksen
/// @link http://codepen.io/jakob-e/pen/doMoML
/// @param {String} $svg - SVG image to encode
/// @return {String} - Encoded SVG data uri
@function svg-uri($svg) {
    $encoded: '';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg) / $slice);

    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);
        $chunk: str-replace($chunk, '"', "'");
        $chunk: str-replace($chunk, '<', '%3C');
        $chunk: str-replace($chunk, '>', '%3E');
        $chunk: str-replace($chunk, '&', '%26');
        $chunk: str-replace($chunk, '#', '%23');
        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }

    @return "data:image/svg+xml;charset=utf8,#{$encoded}";
}

//SVG Mixins.
//These are used when you need to serve the same SVG as background/content from 
//CSS while keeping the ability to render different colors.
@function get-icon($iconName, $color: $color-black) {
  $iconCode: "";
  $transformedColor: str-replace(#{$color}, '#', '%23');

  @if $iconName == close {
    $iconCode: " viewBox='-2 -3 17 17'><path fill='#{$transformedColor}' d='M11.7 1L10.6-.1 5.8 4.7 1.1-.1 0 1l4.8 4.8L0 10.6l1.1 1 4.7-4.8 4.8 4.8 1.1-1-4.8-4.8z' />";
  }

  @return svg-uri("<svg xmlns='http://www.w3.org/2000/svg' #{$iconCode}</svg>");
}

// TEXT
@mixin header--2{
    font-family: Butler;
    font-size: 1.5rem;
    letter-spacing: 1px;
    color: $darkgreen;
}

@mixin paragraph--1 {
    font-family: Archivo;
    font-size: 0.875rem;
    line-height: 1.71;
    letter-spacing: 0.7px;
    color: $matterhorn;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

@mixin custom-scroll() {
  &::-webkit-scrollbar {
    height: 9px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(to top,
        transparent 0px,
        transparent 3px,
        $silver 3px,
        $silver 6px,
        transparent 6px,
        transparent 9px);
    background-repeat: repeat-x;
    background-size: 100% 9px;
    border-radius: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background: $rollingstone;
    border-radius: 9px;

  }

  @supports not (scrollbar-width: thin) {
    & {
      scrollbar-color: $rollingstone
    }
  }
}