.home__product-carousel {
    margin-top: 61px;

    @media (min-width: 768px) {
        margin-top: 64px;
    }
    @media (min-width: 1280px) {
       margin-top: 49px;
    }
    @media (min-width: 1920px) {
        margin-top: 71px;
    }
}

.home-product__carousel {
    
    .slick-next {
        right: 20px;
        top: 0;
        bottom: 0;
    }

    .slick-next ~ .slick-list .slick-slide:not(.slick-active) {

    }
    .slick-next.slick-next-hover-show {
        display: block !important;
    }
}

.home-product__title {
    font-family: 'Archivo-ExtraLight';
    font-size: 16px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: 2.4px;
    text-align: center;
    color: $black;
    text-transform: uppercase;
    margin: 0 0 63px;

    @media (min-width: 768px) {
        margin: 0 0 64px;
    }
    @media (min-width: 1280px) {
       margin:0 0 34px;
    }
    @media (min-width: 1920px) {
        font-size: 20px;
        line-height: 1.55;
        letter-spacing: 3px;
        margin: 0 0 41px;
    }
}

.home-product__carousel {
    margin-left: 20px;
    @media (min-width: 768px) {

    }
    @media (min-width: 1280px) {
        margin-left: 26px;
    }
    @media (min-width: 1920px) {
        margin-left: 41px;
    }
}
.home-product__carousel-item {
    padding-right: 20px;
    
    @media (min-width: 768px) {
        padding-right: 30px;
    }

    @media (min-width: 1920px) {
        padding-right: 41px;
    }

    img {
        border-radius: 8px;
        @media (min-width: 768px) {
            border-radius: 6px;
        }
        @media (min-width: 1280px) {
            border-radius: 6px;
        }
        @media (min-width: 1920px) {
            border-radius: 8px;
        }
    }
}

.home-product__carousel-image {
    padding-bottom: 120.303%;
    @media (min-width: 768px) {
        padding-bottom: 114.29%;
    }
    @media (min-width: 1280px) {
        
    }
    @media (min-width: 1920px) {
        padding-bottom: 114.21%;
    }
}

.home-category__carousel-item-name {
    font-family: 'Butler-Light';
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: 0.6px;
    text-align: center;
    color: $black;
    text-transform: uppercase;
    margin-top: 19px;
    overflow-wrap: break-word;
    @media (min-width: 768px) {
        font-size: 24px;
        line-height: 1;
        letter-spacing: 0.48px;
        margin-top: 28px;
        min-height: 48px;
    }
    @media (min-width: 1280px) {
        min-height: 81px;
    }

    @media (min-width: 1920px) {
        font-size: 30px;
        font-weight: 300;
        line-height: 0.9;
        letter-spacing: 0.6px;
        margin-top: 32px;
    }
}

.home-product__carousel-item-anchor {
    &:hover {
        text-decoration: none;
    }
}

.home-product__carousel-img {
    @media (min-width: 1280px) {
        margin-top: 15px;
        transition: all .3s cubic-bezier(0,0,.5,1);
        &:hover {
            cursor: pointer;
            box-shadow: 2px 4px 16px rgba(0,0,0,0.16);
            transform: scale3d(1.01,1.01,1.01);
            border-radius: 9px;
        }
    }
    &:hover {
        @media (min-width: 1920px) {
            border-radius: 11px;
        }
    } 
}

.home-product__carousel {
    .home-product__carousel-item {
        position: relative;
    }
    .next-arrow {
        display: none !important;
    }
    .slick-next  {
        @media (min-width: 1280px) {
            position: absolute;
            top: calc((100% - 108px)/2);
            right: 60px;
            left: auto;
            cursor: pointer;
            opacity: 0;
            pointer-events: none;
            &::before {
                display: none;
            }
                background: url('../images/icons/slider-right-arrow.svg') center center no-repeat;
                background-size: cover;
                width: 15px;
                height: 27px;
        }
        @media (min-width: 1920px) {
            right: 140px;
        }
    }

    .slick-prev  {
        @media (min-width: 1280px) {
            position: absolute;
            top: calc((100% - 108px)/2);
            right: auto;
            left: 30px;
            cursor: pointer;
            opacity: 0;
            pointer-events: none;
            &::before {
                display: none;
            }
                background: url('../images/icons/left-arrow-icon.svg') center center no-repeat;
                background-size: cover;
                width: 15px;
                height: 27px;
        }
        @media (min-width: 1920px) {
            left: 100px;
        }
    }
}

.home-product__carousel__link {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}