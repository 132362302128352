/* FRETTE: Start Variables
-------------------------- */
@import url("https://fonts.googleapis.com/css?family=Archivo");
@import url("https://fonts.googleapis.com/css?family=PT+Serif:400,700");
@font-face {
  font-family: 'Didot';
  src: url("../fonts/Didot/DidoteTextPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: 'Didot';
  font-weight: bold;
  src: url("../fonts/Didot/DidoteTextPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: 'Didot';
  font-style: italic;
  src: url("../fonts/Didot/DidoteTextPro-Italic.otf") format("opentype"); }

@font-face {
  font-family: 'Didot';
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/Didot/DidoteTextPro-BoldItalic.otf") format("opentype"); }

@font-face {
  font-family: 'HelveticaNeue';
  src: url("../fonts/HelveticaNeue/HelveticaNeueeTextPro-Roman.otf") format("opentype"); }

@font-face {
  font-family: 'HelveticaNeueMd';
  src: url("../fonts/HelveticaNeue/HelveticaNeueeTextPro-Md.otf") format("opentype"); }

/*Replace old fonts with new fonts by default*/
body {
  margin: 0; }

/* FRETTE: End Variables
------------------------ */
/*------------------------------------*\
  #FORMS
\*------------------------------------*/
/*------------------------------------*\
  #TRANSITIONS
\*------------------------------------*/
/**
 * [Adds styles to allow an element's height scale proportionatelly]
 * @param  {[Number]} $width
 * @param  {[Number]} $height
 */
/**
 * [Calculates the percentage aspect ratio (what % height is compared to the width)]
 * @param  {[Number]} $width
 * @param  {[Number]} $height
 * @return {[Number(%)]}
 */
/**
 * *****REMEMBER TO USE MARGIN OR PADDING AND NOT TOP/BOTTOM******
 * [Calculates the center of an element in relation to a provided width and height.
 * Useful to place an absolute element at the center of another when
 * the positioned element cannot be the target's child ]
 * @param  {[Number]} $width
 * @param  {[Number]} $height
 * @return {[Number(%)]}
 */
/**
 * [In the  awful case you can't use Flexbox to vertically align something]
 * @param  {[type]} $transform:       false         [Use transforms to align instead of the absolute trick]
 * @param  {[type]} $pos:             absolute      [position type]
 * @param  {[type]} $posAdj:          0             [position adjustment. If $transform is true, this value only affects the 'top' property.]
 * @param  {[type]} $alignHorizontal: false         [Includes horizontal alignment]
 */
@keyframes flipdown
  0% {
  opacity: 0;
  transform-origin: top center;
  transform: rotateX(-90deg); }

5% {
  opacity: 1; }

80% {
  transform: rotateX(8deg); }

83% {
  transform: rotateX(6deg); }

92% {
  transform: rotateX(-3deg); }

100% {
  transform-origin: top center;
  transform: rotateX(0deg); }

/**
 * [Strip the pesky units from values]
 * @param  {[Number]} $value
 */
/**
 * [Fluid Type]
 */
.slot__container:has(.hero-redesign) {
  background: #efede9;
  margin: 0; }
  .slot__container:has(.hero-redesign) .slot__info {
    background: #E9E1CF;
    padding: 48px 24px;
    flex-direction: column;
    align-items: stretch;
    order: 0; }
  .slot__container:has(.hero-redesign) .slot__text {
    font-size: 16px;
    letter-spacing: 0.03em; }
  .slot__container:has(.hero-redesign) .slot__info-inner {
    gap: 40px; }
  .slot__container:has(.hero-redesign) .slot__cta {
    padding: 0 32px;
    font-family: "Archivo";
    font-size: 14px;
    font-weight: 500;
    line-height: 1.45;
    letter-spacing: 0.01em;
    height: 40px;
    border-radius: 2px;
    background: #212529;
    color: #ffffff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-decoration: none; }

.hero-redesign {
  display: block;
  position: relative;
  overflow: hidden; }
  @media (min-width: 1200px) {
    .hero-redesign .hero__background-img {
      max-height: 80vh;
      object-fit: cover; } }
  @media (min-width: 1200px) {
    .hero-redesign .hero__video-block video {
      max-height: 80vh; } }

.hero__video-block {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .hero__video-block video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .hero__video-block:first-child {
    position: relative;
    height: auto; }
    .hero__video-block:first-child video {
      width: 100%;
      position: relative; }

.hero__content {
  position: absolute;
  inset: auto 0 0;
  padding: 96px 24px 24px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 275px;
  color: #ffffff; }

.hero__content {
  display: flex;
  flex-direction: column;
  gap: 20px; }

.hero__content-subtitle {
  font-family: "Archivo";
  font-size: 14px;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin: 0; }

.hero__content-heading {
  display: flex;
  flex-direction: column;
  gap: 16px; }

.hero__content-title {
  font-family: "Butler";
  font-size: 40px;
  font-weight: 400;
  line-height: 1.25;
  margin: 0;
  letter-spacing: 0; }

.hero__button {
  font-family: "Archivo";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 0.03em;
  text-decoration: underline;
  padding: 0;
  border: 0;
  background: transparent;
  flex: 0 0 auto;
  display: inline-flex; }
  a:is(:hover, :focus, :active) .hero__button {
    text-decoration: underline; }

@media (min-width: 1200px) {
  .slot__container:has(.hero-redesign) {
    margin: 0; }
    .slot__container:has(.hero-redesign) .slot__info {
      padding: 48px 64px 48px 48px;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      gap: 96px; }
    .slot__container:has(.hero-redesign) .slot__text {
      font-size: 18px;
      letter-spacing: 0.02em; }
  .hero__content {
    min-height: 344px;
    padding: 40px 48px;
    gap: 24px;
    justify-content: flex-end; }
  .hero__content-subtitle {
    font-size: 16px;
    letter-spacing: 0.03em; }
  .hero__content-heading {
    flex-direction: row;
    align-items: center;
    gap: 48px; }
  .hero__content-title {
    font-size: 48px; } }

.slot__container:has(.scrollable-list) {
  background: #efede9;
  padding: 40px 0 32px;
  margin-top: 0; }
  .slot__container:has(.scrollable-list) .slot__info {
    background: transparent; }

.scrollable-list__items {
  padding: 0 16px 32px;
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
  overflow: auto; }
  .scrollable-list__items::-webkit-scrollbar {
    height: 9px;
    background: transparent; }
  .scrollable-list__items::-webkit-scrollbar-track {
    background: linear-gradient(to top, transparent 0px, transparent 3px, #bcbcbc 3px, #bcbcbc 6px, transparent 6px, transparent 9px);
    background-repeat: repeat-x;
    background-size: 100% 9px;
    border-radius: 9px; }
  .scrollable-list__items::-webkit-scrollbar-thumb {
    background: #71767c;
    border-radius: 9px; }
  @supports not (scrollbar-width: thin) {
    .scrollable-list__items {
      scrollbar-color: #71767c; } }
  .scrollable-list__items::-webkit-scrollbar-track {
    margin: 9px 16px 9px 16px; }

.scrollable-list {
  position: relative; }
  .scrollable-list .scrollable-nav {
    display: none;
    background: #efede9;
    padding: 0 16px;
    flex-direction: row;
    gap: 4px;
    flex-wrap: nowrap;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(calc(50% - 9px/2)); }
  .scrollable-list .scrollable-nav__prev,
  .scrollable-list .scrollable-nav__next {
    font-size: 0px; }
    .scrollable-list .scrollable-nav__prev[disabled],
    .scrollable-list .scrollable-nav__next[disabled] {
      opacity: .25; }
    .scrollable-list .scrollable-nav__prev::before,
    .scrollable-list .scrollable-nav__next::before {
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><mask id="mask0_1145_16217" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20"><rect width="20" height="20" fill="%23212529"/></mask><g mask="url(%23mask0_1145_16217)"><path d="M13 17.711L5.28857 9.99955L13 2.28809L14.1202 3.40829L7.52878 9.99955L14.1202 16.5908L13 17.711Z" fill="%23212529"/></g></svg>');
      display: block;
      height: 24px;
      width: 24px; }
  .scrollable-list .scrollable-nav__next::before {
    transform: scaleX(-1); }

.scrollable-list-item__media-wrapper {
  aspect-ratio: 1;
  margin-bottom: 12px;
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  background-color: #ffffff; }

.scrollable-list-item__img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.scrollable-list-item {
  width: 284px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start; }

.scrollable-list-item__title {
  font-family: "Archivo";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 0.03em;
  text-align: center;
  color: #212529; }
  .scrollable-list-item:hover .scrollable-list-item__title,
  .scrollable-list-item:active .scrollable-list-item__title {
    text-decoration: underline; }

@media (min-width: 1200px) {
  .slot__container:has(.scrollable-list) {
    margin-top: 0; }
  .scrollable-list-item {
    width: 318px; }
  .scrollable-list:not(:has(.scrollable-nav__prev:disabled):has(.scrollable-nav__next:disabled)) {
    --show-scroll-nav: 1; }
    .scrollable-list:not(:has(.scrollable-nav__prev:disabled):has(.scrollable-nav__next:disabled)) .scrollable-nav {
      display: flex; }
    .scrollable-list:not(:has(.scrollable-nav__prev:disabled):has(.scrollable-nav__next:disabled)) .scrollable-list__items::-webkit-scrollbar-track {
      margin-right: 92px; } }

.slot__container:has(.shop-by-look) {
  position: relative;
  overflow: hidden; }

.shop-by-look__container {
  position: relative; }

.shop-by-look__tile-wrapper {
  width: 250px;
  padding: 20px 12px;
  background: #ffffff;
  margin: 0 11px;
  box-sizing: content-box;
  height: 100%; }

.shop-by-look__background-img {
  width: 100%; }

.shop-by-look__content {
  background: #212529;
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  color: #ffffff;
  text-align: center;
  align-items: center; }

.shop-by-look__content-info {
  display: flex;
  flex-direction: column;
  gap: 48px; }

.shop-by-look__content-title {
  font-size: 20px;
  font-family: "Butler";
  font-weight: 400;
  line-height: 1.25;
  margin: 0; }

.shop-by-look__content-cta {
  flex: 0 0 auto;
  border-radius: 2px;
  border: 1px solid #ffffff;
  height: 48px;
  min-width: 224px;
  font-family: "Archivo";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.01em;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  text-decoration: none;
  text-align: center; }
  .shop-by-look__content-cta:hover, .shop-by-look__content-cta:active {
    color: #212529;
    text-decoration: none;
    background-color: #ffffff; }

.shop-by-look__body {
  font-size: 14px;
  font-family: "Archivo";
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 0.03em; }

.shop-by-look__modal-button {
  position: absolute;
  top: var(--shthlk-btn-y, 0);
  left: var(--shthlk-btn-x, 0);
  transform: translate(-50%, -50%);
  width: 36px;
  height: 36px;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .shop-by-look__modal-button::before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 2px solid #ffffff;
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55); }
  .shop-by-look__modal-button:focus-visible {
    outline: none; }
    .shop-by-look__modal-button:focus-visible::before {
      transform: scale(1.2); }

.shop-by-look__modal {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .shop-by-look:not(.modal-shown) .shop-by-look__modal {
    display: none; }
  .shop-by-look__modal .slick-list {
    padding: 0 !important; }
  .shop-by-look__modal .slick-track {
    display: flex;
    max-width: 100vw !important; }
  .shop-by-look__modal .slick-slide {
    width: auto !important;
    display: flex; }
  .shop-by-look__modal .slide {
    height: 100%; }
  .shop-by-look__modal .tile-body {
    margin-bottom: 0; }
  .shop-by-look__modal .price .strike-through {
    display: none; }

@media (min-width: 1200px) {
  .shop-by-look__container {
    margin: 0; }
  .shop-by-look__modal-close-button {
    position: absolute; }
    .shop-by-look__modal-close-button span {
      display: none; }
  .shop-by-look__content {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    padding: 128px 64px 48px 48px;
    flex-direction: row;
    align-items: center;
    gap: 28px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: left; }
  .shop-by-look__content-title {
    font-size: 32px; }
  .shop-by-look__body {
    font-size: 16px; }
  .shop-by-look__content-cta {
    margin-left: auto; }
  .shop-by-look__content-info {
    gap: 28px; }
  .shop-by-look__modal {
    top: calc(var(--center-y, 50) * 1%);
    left: calc(var(--center-x, 50) * 1%); }
  .shop-by-look__tile-wrapper {
    padding: 20px 20px 32px; }
    .shop-by-look__tile-wrapper:not(:is(.selected > div)) {
      display: none; }
  .shop-by-look__modal-button {
    top: var(--shthlk-btn-y-lg, var(--shthlk-btn-y, 0));
    left: var(--shthlk-btn-x-lg, var(--shthlk-btn-x, 0)); } }

@media (max-width: 1199.98px) {
  .shop-by-look__modal {
    width: 100%;
    height: 100%; }
  .shop-by-look__modal-close-button {
    position: static;
    margin-bottom: 20px;
    width: 250px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end; }
    .shop-by-look__modal-close-button::after {
      content: "\00d7";
      border-radius: 50%;
      border: 1px solid #ffffff;
      line-height: 0;
      position: relative;
      width: 24px;
      height: 24px;
      font-size: 20px;
      line-height: 20px;
      color: #ffffff;
      margin-right: -12px;
      background-color: rgba(0, 0, 0, 0.5); }
    .shop-by-look__modal-close-button::before {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      background: #212529;
      opacity: 0.5; }
    .shop-by-look__modal-close-button span {
      color: #ffffff;
      margin-right: 10px;
      z-index: 1; } }

.shop-by-collection__items {
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
  overflow: auto;
  padding-bottom: 24px; }
  .shop-by-collection__items::-webkit-scrollbar {
    height: 9px;
    background: transparent; }
  .shop-by-collection__items::-webkit-scrollbar-track {
    background: linear-gradient(to top, transparent 0px, transparent 3px, #bcbcbc 3px, #bcbcbc 6px, transparent 6px, transparent 9px);
    background-repeat: repeat-x;
    background-size: 100% 9px;
    border-radius: 9px; }
  .shop-by-collection__items::-webkit-scrollbar-thumb {
    background: #71767c;
    border-radius: 9px; }
  @supports not (scrollbar-width: thin) {
    .shop-by-collection__items {
      scrollbar-color: #71767c; } }
  .shop-by-collection__items::-webkit-scrollbar-track {
    margin-left: 12px;
    margin-right: 12px; }

.sbc-item {
  flex: 0 0 auto;
  overflow: hidden;
  width: 300px;
  height: 450px;
  position: relative;
  display: flex;
  align-items: flex-end; }
  .sbc-item:hover, .sbc-item:active, .sbc-item:focus {
    text-decoration: none; }

.sbc-item__media-wrapper {
  position: absolute;
  inset: 0; }

.sbc-item__video-block {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  transition: all 0.5s; }
  .sbc-item__video-block video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.sbc-item__background-img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  inset: 0; }

.sbc-item__content {
  color: #ffffff;
  position: relative;
  display: flex;
  align-items: flex-start;
  min-height: 260px;
  width: 100%;
  padding: 96px 24px 24px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%); }

.sbc-item__content {
  display: flex;
  flex-direction: column;
  gap: 16px; }

.sbc-item__content-title {
  font-size: 20px;
  font-family: "Archivo";
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0.02em;
  margin: 0; }

.sbc-item__content-inner {
  display: flex;
  flex-direction: column; }

.sbc-item__content-body {
  font-family: "Archivo";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 0.03em;
  transition: all 0.5s;
  max-height: 300px;
  overflow: hidden; }
  .sbc-item__content-body:not(:last-child) {
    margin-bottom: 12px; }

.sbc-item__button {
  background: transparent;
  border: none;
  text-decoration: none;
  padding: 14px 0 12px;
  font-family: "Archivo";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 0.03em;
  margin-right: auto;
  display: inline-flex; }
  a:is(:hover, :focus, :active) .sbc-item__button {
    text-decoration: underline; }

@media (max-width: 1199.98px) {
  .sbc-item__video-block {
    opacity: 0; } }

@media (min-width: 1200px) {
  .shop-by-collection__items {
    width: 100%;
    height: 510px;
    gap: 0;
    overflow: hidden;
    padding: 0;
    background: #000; }
  .sbc-item {
    height: 100%;
    transition: all 0.5s;
    flex: 2; }
    .sbc-item:not(:hover):not(:focus-within) {
      width: auto;
      flex: 1; }
      .shop-by-collection__items:is(:focus-within, :has(.sbc-item:hover)) .sbc-item:not(:hover):not(:focus-within) {
        opacity: 0.5; }
      .sbc-item:not(:hover):not(:focus-within) .sbc-item__video-block {
        opacity: 0; }
      .sbc-item:not(:hover):not(:focus-within) .sbc-item__content-body {
        max-height: 0;
        margin-bottom: 0; }
  .sbc-item__content {
    padding: 128px 48px 32px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); }
  .sbc-item__content-title {
    font-size: 24px; }
  .sbc-item__content--body {
    font-size: 16px; }
  .sbc-item__button {
    font-family: "Archivo";
    font-weight: 500;
    padding: 15px 0; } }

.inspiration-block {
  overflow: hidden;
  position: relative; }

.inspiration__content-body {
  font-family: "Archivo";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 0.03em; }

.inspiration__video-block {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .inspiration__video-block video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.inspiration__content-heading {
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: nowrap;
  align-items: center; }

.inspiration__content-title {
  font-family: "Butler";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
  margin: 0; }

.inspiration__content-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 48px 32px;
  transition: all 0.3s;
  overflow: hidden; }

.inspiration__media-wrapper {
  position: relative; }

.inspiration__content-dark {
  --content-color: #ffffff;
  --content-bg: #212529; }

.inspiration__button {
  flex: 0 0 auto;
  height: 48px;
  min-width: 224px;
  font-family: "Archivo";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.01em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  text-decoration: none;
  text-align: center;
  border-radius: 2px;
  border: 1px solid var(--content-color, #212529);
  color: var(--content-color, #212529);
  background-color: transparent;
  padding: 0 48px;
  transition: all 0.3s; }
  .inspiration__button:hover, .inspiration__button:active {
    text-decoration: none;
    opacity: 0.8; }

.pseudo-icon {
  border-radius: 50%;
  border: 1px solid #ffffff;
  padding: 4px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  position: relative;
  transition: all 0.3s;
  background: var(--pseudo-icon-bg, transparent); }
  .pseudo-icon::before, .pseudo-icon::after {
    content: '';
    display: block;
    width: 13px;
    height: 1.6px;
    background-color: var(--pseudo-icon-color, #ffffff);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .pseudo-icon::after {
    transform: translate(-50%, -50%) rotate(var(--icon-rotation, 0)); }
  .inspiration_show-more .pseudo-icon {
    --icon-rotation: 90deg; }

button:has(.pseudo-icon):active, button:has(.pseudo-icon):hover {
  --pseudo-icon-bg: #ffffff;
  --pseudo-icon-color: #212529; }

.inspiration_show-less {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  font-family: "Archivo";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 0.03em;
  position: absolute;
  top: calc(100% - 48px);
  transform: translateY(-100%);
  right: 64px; }

.inspiration__content-collapse {
  display: flex;
  flex-direction: column;
  gap: 48px;
  transition: all 0.5s; }
  .inspiration__content-collapse > :first-child {
    margin-top: 24px; }

.inspiration_show-more:before {
  content: "";
  display: block;
  position: absolute;
  inset: 0; }

.inspiration_show-less,
.inspiration_show-more {
  transition: all 0.3s; }

@media (min-width: 1200px) {
  .inspiration__content-title {
    font-size: 26px; }
  .inspiration__content-body {
    font-size: 16px; }
  .inspiration__button {
    border-color: #ffffff;
    background-color: #ffffff;
    color: #212529;
    margin-right: auto; }
    .inspiration__button:hover, .inspiration__button:active {
      text-decoration: none;
      opacity: 0.8;
      border-color: #ffffff;
      background-color: rgba(255, 255, 255, 0.8);
      color: #212529; }
  .inspiration__content-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 480px;
    color: #ffffff;
    transition: all 0.3s;
    overflow: hidden;
    justify-content: flex-end;
    padding: 0;
    display: block; }
    .inspiration__content-wrapper:has(.inspiration__content-collapse.show) .inspiration_show-more, .inspiration__content-wrapper:has(.inspiration__content-collapse.collapsing):has(.inspiration_show-more[aria-expanded="true"]) .inspiration_show-more {
      opacity: 0;
      pointer-events: none; }
    .inspiration__content-wrapper:not(:where(:has(.inspiration__content-collapse.show), :has(.inspiration__content-collapse.collapsing):has(.inspiration_show-more[aria-expanded="true"]))) .inspiration_show-less {
      opacity: 0;
      pointer-events: none; }
  .inspiration__content {
    position: relative;
    top: 100%;
    transform: translateY(-100%);
    padding: 48px 64px;
    z-index: 0;
    transition: all 1s; }
    .inspiration__content:has(.inspiration__content-collapse.show), .inspiration__content:has(.inspiration__content-collapse.collapsing):has(.inspiration_show-more[aria-expanded="true"]) {
      top: 50%;
      transform: translateY(-50%); }
      .inspiration__content:has(.inspiration__content-collapse.show)::before, .inspiration__content:has(.inspiration__content-collapse.collapsing):has(.inspiration_show-more[aria-expanded="true"])::before {
        top: 50%;
        transform: translateY(-50%); }
    .inspiration__content::before {
      content: '';
      display: block;
      inset: 0;
      z-index: -1;
      position: absolute;
      background: linear-gradient(rgba(33, 37, 41, 0.5) 0%, rgba(33, 37, 41, 0.5) 100%), rgba(0, 0, 0, 0.1);
      background-blend-mode: overlay, normal;
      height: 150vh;
      pointer-events: none;
      top: 0;
      transform: translateY(0);
      transition: all 1s; }
  .inspiration__content-collapse > :first-child {
    margin-top: 36px; } }

@media (max-width: 1199.98px) {
  .inspiration__content-wrapper {
    background: var(--content-bg, #efede9);
    color: var(--content-color, #212529); }
  .inspiration__content-collapse {
    display: flex !important; } }

.main-hero {
  max-width: 86.25rem;
  margin: 0 auto;
  position: relative; }
  .main-hero:before {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .main-hero .main-hero__content {
    position: relative;
    z-index: 1; }

.main-hero__center {
  position: relative; }
  @media (max-width: 768.98px) {
    .main-hero__center {
      display: flex;
      flex-direction: column-reverse; } }

.main-hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #ffffff; }
  @media (min-width: 769px) {
    .main-hero__overlay {
      padding: 5% 4.375rem; } }
  @media (max-width: 768.98px) {
    .main-hero__overlay {
      padding: 25% 1rem; } }
  .main-hero__overlay.set--alignment-middle {
    align-items: center;
    text-align: center; }
    .main-hero__overlay.set--alignment-middle .main-hero__content {
      max-width: 38.5rem; }
  .main-hero__overlay.set--alignment-right {
    align-items: end;
    text-align: right; }
  .main-hero__overlay.set--v-alignment-top {
    justify-content: flex-start; }
  .main-hero__overlay.set--v-alignment-middle {
    justify-content: center; }
  .main-hero__overlay.set--v-alignment-bottom {
    justify-content: flex-end; }
    @media (min-width: 769px) {
      .main-hero__overlay.set--v-alignment-bottom.set--layout-large {
        padding-bottom: 10.87%; } }

.main-hero__content {
  max-width: 31.125rem; }

.main-hero__title {
  margin-bottom: .35em;
  font-weight: 200; }

.main-hero__description:not(:last-child) {
  margin-bottom: 2rem; }

.home__2up--a .main-2up--a {
  max-width: 1252px;
  margin: 0 auto;
  padding-top: 5rem; }
  .home__2up--a .main-2up--a img {
    width: 100%; }
  .home__2up--a .main-2up--a h3 {
    font-size: 2.375rem;
    line-height: 0.74;
    letter-spacing: 1.4px;
    color: #031e2f;
    margin-bottom: 4rem; }
    @media (max-width: 768.98px) {
      .home__2up--a .main-2up--a h3 {
        font-size: 1.75rem; } }

.home__2up--a .wrapper__2up {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  @media (max-width: 768.98px) {
    .home__2up--a .wrapper__2up {
      display: flex;
      flex-direction: column;
      padding-left: 3rem;
      padding-right: 3rem; } }
  @media (max-width: 479.98px) {
    .home__2up--a .wrapper__2up {
      padding-left: 1rem;
      padding-right: 1rem; } }
  .home__2up--a .wrapper__2up .content__2up {
    width: 50%;
    margin-bottom: 6.25rem; }
    @media (max-width: 768.98px) {
      .home__2up--a .wrapper__2up .content__2up {
        width: 100%;
        margin-bottom: 0; } }
    .home__2up--a .wrapper__2up .content__2up--img .mobile {
      display: none; }
      @media (max-width: 768.98px) {
        .home__2up--a .wrapper__2up .content__2up--img .mobile {
          display: none; } }
    .home__2up--a .wrapper__2up .content__2up--img .desktop {
      display: block;
      margin: 0 auto; }
      @media (max-width: 768.98px) {
        .home__2up--a .wrapper__2up .content__2up--img .desktop {
          display: block; } }
    .home__2up--a .wrapper__2up .content__2up--info h4 {
      letter-spacing: 0.5px;
      line-height: 1.36;
      font-family: "Butler";
      color: #031e2f; }
    .home__2up--a .wrapper__2up .content__2up--info a {
      display: block;
      text-decoration: none;
      font-size: 12px;
      letter-spacing: 2px;
      margin-top: 1.5rem;
      font-family: "Archivo"; }
      @media (max-width: 768.98px) {
        .home__2up--a .wrapper__2up .content__2up--info a {
          margin-bottom: 3.75rem; } }
    .home__2up--a .wrapper__2up .content__2up .small h4,
    .home__2up--a .wrapper__2up .content__2up .large h4 {
      margin-top: 2rem; }
      @media (max-width: 768.98px) {
        .home__2up--a .wrapper__2up .content__2up .small h4,
        .home__2up--a .wrapper__2up .content__2up .large h4 {
          width: 100%; } }
    .home__2up--a .wrapper__2up .content__2up .small {
      width: 64%;
      margin: 0 auto; }
      @media (max-width: 768.98px) {
        .home__2up--a .wrapper__2up .content__2up .small {
          width: 100%;
          margin: 0; } }
      .home__2up--a .wrapper__2up .content__2up .small img {
        max-width: 400px;
        max-height: 400px;
        margin-top: 6rem; }
        @media (max-width: 768.98px) {
          .home__2up--a .wrapper__2up .content__2up .small img {
            margin-top: 0;
            max-width: 100%;
            max-height: 100%; } }
    .home__2up--a .wrapper__2up .content__2up .large img {
      max-width: 626px;
      max-height: 626px; }
      @media (max-width: 768.98px) {
        .home__2up--a .wrapper__2up .content__2up .large img {
          max-width: 100%;
          max-height: 100%; } }
      @media (max-width: 479.98px) {
        .home__2up--a .wrapper__2up .content__2up .large img {
          width: 100%;
          max-height: 344px; } }

.home__2up--b {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto; }
  @media (max-width: 1024px) {
    .home__2up--b {
      width: 100%; } }
  @media (max-width: 479.98px) {
    .home__2up--b .featured-hotel__paragraph {
      display: none; } }
  .home__2up--b .main-2up--b {
    margin-bottom: 6rem; }
    @media (max-width: 768.98px) {
      .home__2up--b .main-2up--b {
        margin-bottom: 2rem; } }
    .home__2up--b .main-2up--b .wrapper__2up--b {
      width: 100%;
      display: flex;
      align-items: center; }
      @media (max-width: 768.98px) {
        .home__2up--b .main-2up--b .wrapper__2up--b {
          display: block;
          height: 100%; } }
    .home__2up--b .main-2up--b .left {
      flex-direction: row; }
    .home__2up--b .main-2up--b .right {
      flex-direction: row-reverse; }
  .home__2up--b img {
    height: auto; }
    @media (max-width: 479.98px) {
      .home__2up--b img {
        max-height: 345px;
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem; } }

.content--img {
  width: 50%; }
  @media (max-width: 768.98px) {
    .content--img {
      width: 100%;
      max-width: 680px;
      margin: 0 auto; } }
  @media (min-width: 544px) and (max-width: 991.98px) {
    .content--img {
      max-width: 100%; } }
  .content--img .mobile {
    display: none; }
  @media (max-width: 479.98px) {
    .content--img .mobile {
      display: block; }
    .content--img .desktop {
      display: none; } }

.content--info {
  width: 50%;
  position: relative; }
  @media (min-width: 992px) {
    .content--info {
      padding-left: 9.375rem;
      padding-right: 9.375rem; } }
  @media (min-width: 769px) and (max-width: 991.98px) {
    .content--info {
      padding-left: 4.25rem;
      padding-right: 4.25rem; } }
  @media (max-width: 768.98px) {
    .content--info {
      position: static;
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-bottom: 3.75rem; } }
  .content--info h2 {
    font-weight: bolder; }
  .content--info p {
    color: #757575; }

@media (max-width: 768.98px) {
  .content--alignment {
    width: 100%;
    transform: none;
    margin: 1.75rem 0 2rem 0; } }

.featured-hotel__img-grid-heading {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: block;
  font-weight: normal; }

.featured-hotel__paragraph {
  margin-bottom: 1.5rem; }

.hotel-partner__no-line .line-animation {
  display: none; }

.up-content__container {
  position: relative;
  max-width: 84.75rem;
  margin: 5.125rem auto; }
  @media (min-width: 769px) {
    .up-content__container {
      padding-top: 5.5rem;
      padding-bottom: 7rem; } }
  @media (max-width: 768.98px) {
    .up-content__container {
      padding-top: 3.25rem;
      padding-bottom: 4.75rem;
      padding-left: 1rem; } }

.up-content__center {
  max-width: 78.25rem;
  margin: 0 auto; }

.up-content__title {
  margin-bottom: 2.75rem; }

@media (min-width: 992px) {
  .up-content__title,
  .up-content__copy {
    padding-left: 2.5rem;
    padding-right: 2.5rem; } }

@media (min-width: 769px) and (max-width: 1199.98px) {
  .up-content__title,
  .up-content__copy {
    padding-left: 1.25rem;
    padding-right: 1.25rem; } }

.up-content__list:not(.slick-initialized) {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto; }
  .up-content__list:not(.slick-initialized) .up-content__item {
    flex: 1 0 80%; }

@media (max-width: 768.98px) {
  .up-content__item {
    margin-right: 2.75rem;
    padding: 0; } }

.up-content__copy-description {
  margin-top: 1.5rem;
  margin-bottom: 0; }
  .up-content__copy-description.text-color--matterhorn {
    color: #505050; }

.numerical--sign {
  font-family: "PT Serif", serif; }

@media (min-width: 769px) {
  .home__carousel {
    margin-bottom: 8rem; } }

@media (max-width: 768.98px) {
  .home__carousel {
    margin-bottom: 3rem; } }

.home-category__title {
  margin-top: 4rem; }
  @media (min-width: 769px) {
    .home-category__title {
      margin-bottom: 3.25rem; } }
  @media (max-width: 768.98px) {
    .home-category__title {
      margin-bottom: 1.25rem; } }

.home-category__carousel .slick-arrow {
  top: 45%; }

.home-category__carousel .slick-prev {
  left: 21%;
  transform: translate(-50%, -50%); }

.home-category__carousel .slick-next {
  right: 21%;
  transform: translate(50%, -50%); }

.home-category__carousel:not(.slick-initialized) {
  display: flex;
  overflow: auto;
  padding: 0 21%; }
  .home-category__carousel:not(.slick-initialized) .home-category__carousel-group {
    flex: 1 0 100%; }

@media (min-width: 1200px) {
  .home-category__carousel-group {
    margin-right: 4.0625rem;
    margin-left: 4.0625rem; }
    .home-category__carousel-group:hover, .home-category__carousel-group:active, .home-category__carousel-group:focus {
      outline: 1px dotted #ccc; } }

@media (min-width: 769px) and (max-width: 1199.98px) {
  .home-category__carousel-group {
    margin-right: 4.5vw;
    margin-left: 4.5vw; } }

@media (max-width: 768.98px) {
  .home-category__carousel-group {
    margin-right: 1rem; } }

@media (min-width: 1200px) {
  .home-category__carousel-item .home-category__carousel-item-anchor:active, .home-category__carousel-item .home-category__carousel-item-anchor:focus {
    outline: 1px dotted #ccc; } }

@media (min-width: 769px) {
  .home-category__carousel-item {
    flex: 1 1 auto; }
    .home-category__carousel-item:not(:last-child) {
      margin-right: 1.625rem; } }

@media (max-width: 768.98px) {
  .home-category__carousel-item:not(:last-child) {
    margin-right: 1rem; } }

.home-category__carousel-item-anchor {
  outline: none; }

.home-category__carousel-item-name {
  letter-spacing: 0.4px;
  margin-top: 1rem; }

.home__editorial {
  max-width: 1440px;
  margin: 0 auto; }

.main-editorial {
  position: relative; }
  .main-editorial .ctas__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 8.25rem;
    counter-reset: section;
    list-style: none;
    padding: 0;
    margin: 0; }
    .main-editorial .ctas__wrapper a {
      margin-bottom: 1.25rem;
      text-decoration: none;
      cursor: pointer;
      line-height: 1.83;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-family: "Archivo";
      display: block; }
      .main-editorial .ctas__wrapper a::before {
        counter-increment: section;
        content: "0" counter(section);
        margin-right: 1rem; }

.main-editorial__img {
  position: relative; }
  .main-editorial__img:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5); }
  .main-editorial__img .mobile {
    display: none; }
    @media (max-width: 479.98px) {
      .main-editorial__img .mobile {
        display: block; } }
  @media (max-width: 479.98px) {
    .main-editorial__img .desktop {
      display: none; } }
  .main-editorial__img img {
    width: 100%; }

.main-editorial__info {
  position: absolute;
  bottom: 0;
  left: 6.5%;
  right: 0;
  display: flex;
  flex-direction: row; }
  .main-editorial__info h2 {
    color: #ffffff;
    letter-spacing: 1px;
    font-family: "Butler"; }
    @media (max-width: 479.98px) {
      .main-editorial__info h2 {
        padding-bottom: 1rem;
        line-height: 1.32; } }

.editorial--text {
  width: 44%;
  margin-right: 4%;
  margin-bottom: 5%; }
  @media (max-width: 768.98px) {
    .editorial--text {
      width: 80%; } }
  @media (max-width: 479.98px) {
    .editorial--text {
      width: 100%;
      margin-bottom: 2.5rem; } }
  .editorial--text p {
    color: #ffffff;
    margin-top: 1.6rem;
    margin-bottom: 1.5rem;
    line-height: 1.71;
    letter-spacing: 0.8px;
    font-family: "Archivo"; }
    @media (max-width: 479.98px) {
      .editorial--text p {
        display: none; } }
  .editorial--text a {
    text-decoration: none;
    color: #ffffff;
    font-family: "Archivo"; }

.editorial--ctas {
  width: 50%;
  padding-top: 4.6875rem; }
  @media (max-width: 1199.98px) {
    .editorial--ctas {
      display: none; } }

@media (min-width: 769px) {
  .home__frette--about {
    margin-top: 6.25rem; } }

@media (max-width: 479.98px) {
  .home__frette--about {
    margin-top: 2rem; } }

.frette__about {
  max-width: 1440px;
  margin: 0 auto 5rem; }
  .frette__about .container--about-top {
    align-items: center; }
  .frette__about .container--about-top {
    display: flex; }
  .frette__about .container--about-bottom {
    display: flex;
    flex-direction: row;
    max-width: 1022px;
    margin: 5.5rem auto 0; }
    @media (max-width: 479.98px) {
      .frette__about .container--about-bottom {
        display: flex;
        flex-direction: column;
        padding-top: 3rem; } }
    .frette__about .container--about-bottom .about-bottom__info {
      width: 50%;
      padding-top: 1rem;
      padding-bottom: 1.5rem; }
      @media (max-width: 1199.98px) {
        .frette__about .container--about-bottom .about-bottom__info {
          padding-left: 2rem;
          padding-right: 2rem; } }
      @media (max-width: 768.98px) {
        .frette__about .container--about-bottom .about-bottom__info {
          width: 100%;
          margin-bottom: 3.125rem;
          padding-left: 1rem;
          padding-right: 1rem; } }
      .frette__about .container--about-bottom .about-bottom__info:first-child {
        border-right: solid 1px #d0d0d0; }
        @media (max-width: 479.98px) {
          .frette__about .container--about-bottom .about-bottom__info:first-child {
            border: none; } }
      .frette__about .container--about-bottom .about-bottom__info:last-child {
        padding-left: 7rem; }
        @media (max-width: 479.98px) {
          .frette__about .container--about-bottom .about-bottom__info:last-child {
            padding-left: 1rem; } }
    .frette__about .container--about-bottom .about-bottom__info-title {
      line-height: 1.42;
      margin-bottom: 1.5rem; }
  .frette__about .left {
    flex-direction: row; }
    @media (max-width: 479.98px) {
      .frette__about .left {
        flex-direction: column; } }
  .frette__about .right {
    flex-direction: row-reverse; }
    @media (max-width: 1199.98px) {
      .frette__about .right {
        flex-direction: column-reverse; } }
  .frette__about--img {
    max-width: 58.375rem;
    margin: 0 auto; }
    .frette__about--img .mobile {
      display: none; }
    @media (max-width: 479.98px) {
      .frette__about--img .desktop {
        display: none; }
      .frette__about--img .mobile {
        display: block; } }
    .frette__about--img img {
      width: 100%;
      height: 100%; }
      @media (max-width: 479.98px) {
        .frette__about--img img {
          width: 100%;
          height: 258px; } }
  .frette__about--info {
    max-width: 22.75rem;
    margin: auto; }
    @media (max-width: 1199.98px) {
      .frette__about--info {
        padding: 0 1rem; } }
    @media (max-width: 768.98px) {
      .frette__about--info {
        padding: 0 1rem;
        width: 100%; } }
    @media (max-width: 479.98px) {
      .frette__about--info {
        padding: 0 1rem;
        margin-top: 1.875rem;
        width: 100%; } }
    .frette__about--info .info--box {
      border: solid 6px #000;
      height: 100%;
      padding-left: 3.75rem;
      padding-right: 3.75rem;
      padding-bottom: 4.5rem;
      text-align: left; }
      @media (max-width: 1199.98px) {
        .frette__about--info .info--box {
          padding-left: 1rem;
          padding-right: 1rem;
          padding-bottom: 1rem; } }
      @media (max-width: 768.98px) {
        .frette__about--info .info--box {
          padding-left: 1rem;
          padding-right: 1rem;
          padding-bottom: 1rem;
          text-align: center; } }
      @media (max-width: 479.98px) {
        .frette__about--info .info--box {
          padding-left: 2.625rem;
          padding-right: 2.625rem;
          text-align: center; } }
      @media (max-width: 479.98px) {
        .frette__about--info .info--box {
          padding-bottom: 0; } }
      .frette__about--info .info--box h2 {
        line-height: 1.2;
        letter-spacing: 0.5px;
        margin-top: 4.5rem;
        font-family: "Butler";
        color: #031e2f; }
        @media (max-width: 1199.98px) {
          .frette__about--info .info--box h2 {
            margin-top: 1.2rem; } }
        @media (max-width: 768.98px) {
          .frette__about--info .info--box h2 {
            margin-top: 1.2rem; } }
        @media (max-width: 479.98px) {
          .frette__about--info .info--box h2 {
            margin-top: 2.75rem;
            font-size: 2.5rem; } }
      .frette__about--info .info--box p {
        line-height: 1.71;
        letter-spacing: 0.5px;
        color: #505050;
        font-size: 0.875rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        font-family: "Archivo"; }
      @media (max-width: 479.98px) {
        .frette__about--info .info--box a {
          margin-bottom: 3.25rem; } }

.main-hero-carousel.min-h-600 {
  min-height: 600px; }

.main-hero__carousel {
  position: relative;
  margin-top: 0;
  padding-top: 15px; }
  @media (min-width: 768px) {
    .main-hero__carousel {
      padding-top: 20px; } }
  @media (min-width: 1280px) {
    .main-hero__carousel {
      padding-top: 0; } }
  .main-hero__carousel .slick-prev {
    left: 20px;
    top: 26.5%;
    color: #ffffff;
    width: 14px;
    height: 26px;
    background: url("../images/icons/left-arrow-icon.svg") center center no-repeat;
    background-size: cover;
    opacity: 1;
    position: absolute; }
    @media (min-width: 768px) {
      .main-hero__carousel .slick-prev {
        top: 32.5%;
        width: 15px;
        height: 27px; } }
    @media (min-width: 1280px) {
      .main-hero__carousel .slick-prev {
        top: 47.2%;
        left: 48px; } }
    @media (min-width: 1920px) {
      .main-hero__carousel .slick-prev {
        left: 73px;
        width: 22px;
        height: 41px; } }
    .main-hero__carousel .slick-prev::before {
      display: none; }
  .main-hero__carousel .slick-next {
    right: 20px;
    top: 26.5%;
    color: #ffffff;
    width: 14px;
    height: 26px;
    position: absolute;
    background: url("../images/icons/right-arrow-icon.svg") center center no-repeat;
    background-size: cover;
    opacity: 1; }
    @media (min-width: 768px) {
      .main-hero__carousel .slick-next {
        top: 32.5%;
        width: 15px;
        height: 27px; } }
    @media (min-width: 1280px) {
      .main-hero__carousel .slick-next {
        top: 47.2%;
        right: 48px; } }
    @media (min-width: 1920px) {
      .main-hero__carousel .slick-next {
        right: 73px;
        width: 22px;
        height: 41px; } }
    .main-hero__carousel .slick-next::before {
      display: none; }
  .main-hero__carousel .slick-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 335px; }
    @media (min-width: 768px) {
      .main-hero__carousel .slick-dots {
        bottom: 306px; } }
    @media (min-width: 1280px) {
      .main-hero__carousel .slick-dots {
        bottom: 21px;
        top: auto;
        margin-bottom: 0; } }
    @media (min-width: 1920px) {
      .main-hero__carousel .slick-dots {
        margin-bottom: 0;
        bottom: 31px; } }
  @media (max-width: 1279.98px) {
    .main-hero__carousel {
      max-width: calc(100% - 40px);
      margin: auto; } }
  @media (max-width: 767.98px) {
    .main-hero__carousel .desktop {
      display: none; } }
  @media (min-width: 768px) {
    .main-hero__carousel .mobile {
      display: none; } }

.main-hero__carousel-item {
  position: relative;
  display: inline-block; }
  @media (min-width: 1280px) {
    .main-hero__carousel-item {
      margin: 0 8px; } }
  @media (min-width: 1920px) {
    .main-hero__carousel-item {
      margin: 0 10px; } }
  .main-hero__carousel-item .main-hero__carousel-image {
    position: relative; }
    .main-hero__carousel-item .main-hero__carousel-image:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(128, 128, 128, 0) 74%);
      border-radius: 7px; }
      @media (min-width: 768px) {
        .main-hero__carousel-item .main-hero__carousel-image:after {
          border-radius: 6px;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(67, 67, 67, 0.52) 69%, rgba(0, 0, 0, 0.71)); } }
      @media (min-width: 1920px) {
        .main-hero__carousel-item .main-hero__carousel-image:after {
          border-radius: 8px; } }
  .main-hero__carousel-item img {
    border-radius: 7px;
    width: 100%; }
    @media (min-width: 768px) {
      .main-hero__carousel-item img {
        border-radius: 6px; } }
    @media (min-width: 1280px) {
      .main-hero__carousel-item img {
        border-radius: 6px; } }
    @media (min-width: 1920px) {
      .main-hero__carousel-item img {
        border-radius: 8px; } }

@media (min-width: 1280px) {
  .main-hero__carousel-item-content-center {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0; } }

@media (min-width: 1920px) {
  .main-hero__carousel-item-content-center {
    bottom: 72px; } }

@media (min-width: 1280px) {
  .main-hero__carousel-item-content-center .main-hero__carousel-text, .main-hero__carousel-item-content-center .main-hero__carousel-collections {
    text-align: center; } }

@media (min-width: 1280px) {
  .main-hero__carousel-item-content-topleft {
    position: absolute;
    top: 20px;
    left: 21px; } }

@media (min-width: 1920px) {
  .main-hero__carousel-item-content-topleft {
    top: 29px;
    left: 32px; } }

@media (min-width: 1280px) {
  .main-hero__carousel-item-content-topleft .main-hero__carousel-text, .main-hero__carousel-item-content-topleft .main-hero__carousel-collections {
    text-align: left; } }

@media (min-width: 1280px) {
  .main-hero__carousel-item-content-bottomleft {
    position: absolute;
    left: 20px;
    bottom: 21px; } }

@media (min-width: 1920px) {
  .main-hero__carousel-item-content-bottomleft {
    bottom: 31px;
    left: 31px; } }

@media (min-width: 1280px) {
  .main-hero__carousel-item-content-bottomleft .main-hero__carousel-text, .main-hero__carousel-item-content-bottomleft .main-hero__carousel-collections {
    text-align: left; } }

@media (min-width: 1280px) {
  .main-hero__carousel-item-content-topright {
    position: absolute;
    top: 20px;
    right: 22px; } }

@media (min-width: 1920px) {
  .main-hero__carousel-item-content-topright {
    top: 29px;
    right: 32px; } }

@media (min-width: 1280px) {
  .main-hero__carousel-item-content-topright .main-hero__carousel-text, .main-hero__carousel-item-content-topright .main-hero__carousel-collections {
    text-align: right; } }

@media (min-width: 1280px) {
  .main-hero__carousel-item-content-bottomright {
    position: absolute;
    bottom: 21px;
    right: 22px; } }

@media (min-width: 1920px) {
  .main-hero__carousel-item-content-bottomright {
    bottom: 31px;
    right: 31px; } }

@media (min-width: 1280px) {
  .main-hero__carousel-item-content-bottomright .main-hero__carousel-text, .main-hero__carousel-item-content-bottomright .main-hero__carousel-collections {
    text-align: right; } }

@media (min-width: 1280px) and (max-width: 1919.98px) {
  .main-hero__carousel-item-content-center + .main-hero__carousel-cta {
    right: 21px; } }

@media (min-width: 1280px) {
  .main-hero__carousel-item-content-bottomright + .main-hero__carousel-cta {
    position: absolute;
    bottom: 21px;
    left: 21px; } }

@media (min-width: 1920px) {
  .main-hero__carousel-item-content-bottomright + .main-hero__carousel-cta {
    position: absolute;
    bottom: 31px;
    left: 31px; } }

.main-hero__carousel-collections {
  font-family: 'Archivo-Regular';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 2.11px;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  margin: 28px 0 16px; }
  @media (min-width: 768px) {
    .main-hero__carousel-collections {
      margin: 29px 0 17px; } }
  @media (min-width: 1280px) {
    .main-hero__carousel-collections {
      font-family: 'Archivo-SemiBold';
      font-size: 8px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 1.41px;
      text-align: center;
      color: #ffffff;
      margin: 0 0 8px 0; } }
  @media (min-width: 1920px) {
    .main-hero__carousel-collections {
      font-family: 'Archivo-SemiBold';
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 2.11px;
      text-align: center;
      color: #ffffff;
      margin: 0 0 11px 0; } }

.main-hero__carousel-text {
  font-family: 'Butler-Light';
  font-size: 45px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.84;
  letter-spacing: -1.35px;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 103px; }
  @media (min-width: 768px) {
    .main-hero__carousel-text {
      margin-bottom: 108px; } }
  @media (min-width: 1280px) {
    .main-hero__carousel-text {
      font-size: 46px;
      line-height: 0.83;
      letter-spacing: -1.38px;
      color: #ffffff;
      margin-bottom: 0; } }
  @media (min-width: 1920px) {
    .main-hero__carousel-text {
      font-size: 71px;
      line-height: 0.82;
      letter-spacing: -2.13px;
      color: #ffffff; } }

.main-hero__carousel-cta {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  background-color: #000;
  font-family: 'Archivo-Medium';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 2.11px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  text-transform: uppercase;
  padding: 24px 0; }
  .main-hero__carousel-cta:hover {
    text-decoration: none; }
    @media (max-width: 1279.98px) {
      .main-hero__carousel-cta:hover {
        color: #ffffff; } }
  @media (min-width: 1280px) {
    .main-hero__carousel-cta {
      position: absolute;
      top: auto;
      bottom: 21px;
      right: 22px;
      width: 169px;
      height: 41px;
      border-radius: 6px;
      background-color: #ffffff;
      color: #000;
      font-size: 9px;
      opacity: 1;
      line-height: 0.56;
      letter-spacing: 1.58px;
      padding: 18px 0; } }
  @media (min-width: 1920px) {
    .main-hero__carousel-cta {
      width: 225px;
      height: 60px;
      border-radius: 8px;
      line-height: 1;
      letter-spacing: 2.11px;
      bottom: 31px;
      right: 32px;
      padding: 25px 0; } }

.slick--simple-dots .slick-dots button {
  padding: 0 5px; }
  @media (min-width: 1920px) {
    .slick--simple-dots .slick-dots button {
      padding: 0 6px; } }
  .slick--simple-dots .slick-dots button:after {
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 100px;
    content: ''; }
    @media (min-width: 1920px) {
      .slick--simple-dots .slick-dots button:after {
        width: 7px;
        height: 7px; } }

.slick--white-dots .slick-dots button:after {
  background-color: white;
  opacity: 0.4; }

.slick--white-dots .slick-dots .slick-active button:after {
  background-color: white;
  opacity: 1; }

.desktop-show {
  display: none; }
  @media (min-width: 1280px) {
    .desktop-show {
      display: block; } }

.slick-slide .fadeinDown {
  opacity: 0;
  transform: translateY(-30px);
  transition: all 2s ease; }

.slick-current .fadeinDown {
  opacity: 1;
  transform: translateY(0); }

.slick-slide .fadeinDown2 {
  opacity: 0;
  transform: translateY(-30px);
  transition: all 2.5s ease; }

.slick-current .fadeinDown2 {
  opacity: 1;
  transform: translateY(0); }

.home__product-carousel {
  margin-top: 61px; }
  @media (min-width: 768px) {
    .home__product-carousel {
      margin-top: 64px; } }
  @media (min-width: 1280px) {
    .home__product-carousel {
      margin-top: 49px; } }
  @media (min-width: 1920px) {
    .home__product-carousel {
      margin-top: 71px; } }

.home-product__carousel .slick-next {
  right: 20px;
  top: 0;
  bottom: 0; }

.home-product__carousel .slick-next.slick-next-hover-show {
  display: block !important; }

.home-product__title {
  font-family: 'Archivo-ExtraLight';
  font-size: 16px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: 2.4px;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  margin: 0 0 63px; }
  @media (min-width: 768px) {
    .home-product__title {
      margin: 0 0 64px; } }
  @media (min-width: 1280px) {
    .home-product__title {
      margin: 0 0 34px; } }
  @media (min-width: 1920px) {
    .home-product__title {
      font-size: 20px;
      line-height: 1.55;
      letter-spacing: 3px;
      margin: 0 0 41px; } }

.home-product__carousel {
  margin-left: 20px; }
  @media (min-width: 1280px) {
    .home-product__carousel {
      margin-left: 26px; } }
  @media (min-width: 1920px) {
    .home-product__carousel {
      margin-left: 41px; } }

.home-product__carousel-item {
  padding-right: 20px; }
  @media (min-width: 768px) {
    .home-product__carousel-item {
      padding-right: 30px; } }
  @media (min-width: 1920px) {
    .home-product__carousel-item {
      padding-right: 41px; } }
  .home-product__carousel-item img {
    border-radius: 8px; }
    @media (min-width: 768px) {
      .home-product__carousel-item img {
        border-radius: 6px; } }
    @media (min-width: 1280px) {
      .home-product__carousel-item img {
        border-radius: 6px; } }
    @media (min-width: 1920px) {
      .home-product__carousel-item img {
        border-radius: 8px; } }

.home-product__carousel-image {
  padding-bottom: 120.303%; }
  @media (min-width: 768px) {
    .home-product__carousel-image {
      padding-bottom: 114.29%; } }
  @media (min-width: 1920px) {
    .home-product__carousel-image {
      padding-bottom: 114.21%; } }

.home-category__carousel-item-name {
  font-family: 'Butler-Light';
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: 0.6px;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  margin-top: 19px;
  overflow-wrap: break-word; }
  @media (min-width: 768px) {
    .home-category__carousel-item-name {
      font-size: 24px;
      line-height: 1;
      letter-spacing: 0.48px;
      margin-top: 28px;
      min-height: 48px; } }
  @media (min-width: 1280px) {
    .home-category__carousel-item-name {
      min-height: 81px; } }
  @media (min-width: 1920px) {
    .home-category__carousel-item-name {
      font-size: 30px;
      font-weight: 300;
      line-height: 0.9;
      letter-spacing: 0.6px;
      margin-top: 32px; } }

.home-product__carousel-item-anchor:hover {
  text-decoration: none; }

@media (min-width: 1280px) {
  .home-product__carousel-img {
    margin-top: 15px;
    transition: all 0.3s cubic-bezier(0, 0, 0.5, 1); }
    .home-product__carousel-img:hover {
      cursor: pointer;
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);
      transform: scale3d(1.01, 1.01, 1.01);
      border-radius: 9px; } }

@media (min-width: 1920px) {
  .home-product__carousel-img:hover {
    border-radius: 11px; } }

.home-product__carousel .home-product__carousel-item {
  position: relative; }

.home-product__carousel .next-arrow {
  display: none !important; }

@media (min-width: 1280px) {
  .home-product__carousel .slick-next {
    position: absolute;
    top: calc((100% - 108px)/2);
    right: 60px;
    left: auto;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    background: url("../images/icons/slider-right-arrow.svg") center center no-repeat;
    background-size: cover;
    width: 15px;
    height: 27px; }
    .home-product__carousel .slick-next::before {
      display: none; } }

@media (min-width: 1920px) {
  .home-product__carousel .slick-next {
    right: 140px; } }

@media (min-width: 1280px) {
  .home-product__carousel .slick-prev {
    position: absolute;
    top: calc((100% - 108px)/2);
    right: auto;
    left: 30px;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    background: url("../images/icons/left-arrow-icon.svg") center center no-repeat;
    background-size: cover;
    width: 15px;
    height: 27px; }
    .home-product__carousel .slick-prev::before {
      display: none; } }

@media (min-width: 1920px) {
  .home-product__carousel .slick-prev {
    left: 100px; } }

.home-product__carousel__link {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.home__section-3 {
  margin-top: 80px;
  overflow: hidden; }
  @media (min-width: 768px) {
    .home__section-3 {
      margin-top: 75px; } }
  @media (min-width: 1280px) {
    .home__section-3 {
      margin-top: 77px; } }
  @media (min-width: 1920px) {
    .home__section-3 {
      margin-top: 98px; } }
  @media (max-width: 767.98px) {
    .home__section-3 {
      display: block !important; } }
  .home__section-3 .shop-collection {
    background-color: #efede9;
    display: flex;
    flex-direction: column; }
    @media (max-width: 767.98px) {
      .home__section-3 .shop-collection {
        padding: 0 20px 56px !important;
        max-width: 100%; } }
  @media (max-width: 767.98px) {
    .home__section-3 .home-tooltip {
      max-width: 100%;
      padding: 20px 20px 0;
      background-color: #efede9; } }

.home-carousel__tooltip .slick-prev {
  background: url("../images/icons/left-arrow-icon.svg") center center no-repeat;
  background-size: cover;
  width: 15px;
  height: 27px;
  opacity: 1; }
  @media (min-width: 1280px) {
    .home-carousel__tooltip .slick-prev {
      top: 47.3%;
      color: #ffffff;
      left: 29px; } }
  @media (min-width: 1920px) {
    .home-carousel__tooltip .slick-prev {
      width: 22px;
      height: 41px;
      left: 44px; } }
  .home-carousel__tooltip .slick-prev::before {
    display: none; }

.home-carousel__tooltip .slick-next {
  background: url("../images/icons/right-arrow-icon.svg") center center no-repeat;
  background-size: cover;
  width: 15px;
  height: 27px;
  opacity: 1; }
  @media (min-width: 1280px) {
    .home-carousel__tooltip .slick-next {
      top: 47.3%;
      color: #ffffff;
      right: 29px; } }
  @media (min-width: 1920px) {
    .home-carousel__tooltip .slick-next {
      width: 22px;
      height: 41px;
      right: 44px; } }
  .home-carousel__tooltip .slick-next::before {
    display: none; }

@media (min-width: 1280px) {
  .home-carousel__tooltip .slick-dots {
    bottom: -20px;
    margin-bottom: 0; } }

@media (min-width: 1920px) {
  .home-carousel__tooltip .slick-dots {
    bottom: -20px; } }

.home-carousel__tooltip .slick-dots button:after {
  width: 7px;
  height: 7px;
  opacity: 1;
  background: #757575; }
  @media (min-width: 1920px) {
    .home-carousel__tooltip .slick-dots button:after {
      width: 9px;
      height: 9px; } }

.home-carousel__tooltip .slick-dots .slick-active button:after {
  background: #000000; }

.shop-collection__cta1, .shop-collection__cta2 {
  font-family: 'Archivo-Light';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: 0.42px;
  color: #5f5f5f; }
  @media (min-width: 768px) {
    .shop-collection__cta1, .shop-collection__cta2 {
      font-size: 12px;
      line-height: 1.92;
      letter-spacing: 0.36px; } }
  @media (min-width: 1920px) {
    .shop-collection__cta1, .shop-collection__cta2 {
      font-size: 16px;
      line-height: 1.44;
      letter-spacing: 0.48px; } }

.shop-collection__ctas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 22px; }
  @media (min-width: 768px) {
    .shop-collection__ctas {
      padding: 17px 20px 0 19px; } }
  @media (min-width: 1280px) {
    .shop-collection__ctas {
      padding: 17px 19px 0; } }
  @media (min-width: 1920px) {
    .shop-collection__ctas {
      padding: 25px 29px 0; } }
  @media screen and (max-width: 767.98px) {
    .shop-collection__ctas {
      position: relative;
      padding-bottom: 60px; } }

.shop-collection__info {
  padding: 50px 0; }
  @media (min-width: 768px) {
    .shop-collection__info {
      width: 257px;
      margin: auto; } }
  @media (min-width: 1600px) {
    .shop-collection__info {
      width: 418px; } }
  @media screen and (max-width: 767.98px) {
    .shop-collection__info {
      padding: 0; } }

.shop-collection__title {
  font-family: 'Archivo-Light';
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: 0.6px;
  text-align: center;
  color: #000;
  margin: 0 0 28px; }
  @media (min-width: 768px) {
    .shop-collection__title {
      font-size: 22px;
      line-height: 1.18;
      letter-spacing: 0.44px;
      margin: 0 0 12px; } }
  @media (min-width: 1600px) {
    .shop-collection__title {
      font-size: 35px;
      line-height: 1.14;
      letter-spacing: 0.7px;
      margin: 0 0 13px; } }

.shop-collection__line {
  width: 105px;
  height: 1px;
  background-color: #bcbcbc;
  margin: auto; }
  @media (min-width: 768px) {
    .shop-collection__line {
      width: 70px; } }
  @media (min-width: 1600px) {
    .shop-collection__line {
      width: 105px; } }

.shop-collection__content {
  font-family: 'Archivo-Light';
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.48px;
  text-align: center;
  color: #656565;
  margin: 29px 0 39px; }
  @media (min-width: 768px) {
    .shop-collection__content {
      font-size: 12px;
      line-height: 1.42;
      letter-spacing: 0.36px;
      margin: 22px 0 20px; } }
  @media (min-width: 1600px) {
    .shop-collection__content {
      font-size: 16px;
      line-height: 1.44;
      letter-spacing: 0.48px;
      margin: 28px 0 38px; } }

.shop-collection__cta3 {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  background-color: #000;
  font-family: 'Archivo-Medium';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 2.11px;
  text-align: center;
  color: #ffffff;
  display: block;
  text-transform: uppercase;
  padding: 24px 0;
  margin: auto; }
  .shop-collection__cta3:hover {
    color: #ffffff;
    text-decoration: none; }
  .shop-collection__cta3:focus {
    outline: 2px solid #007bff; }
  @media (min-width: 768px) {
    .shop-collection__cta3 {
      width: 169px;
      height: 41px;
      border-radius: 6px;
      font-size: 9px;
      line-height: 0.56;
      letter-spacing: 1.58px;
      padding: 18px 0; } }
  @media (min-width: 1600px) {
    .shop-collection__cta3 {
      border-radius: 8px;
      width: 225px;
      height: 60px;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 2.11px;
      padding: 24px 0; } }

@media (max-width: 1279.98px) {
  .home-tooltip__carousel-item-content {
    display: none; } }

.home-tooltip__carousel-item-content .tooltip-1, .home-tooltip__carousel-item-content .tooltip-2, .home-tooltip__carousel-item-content .tooltip-3 {
  z-index: 2; }
  .home-tooltip__carousel-item-content .tooltip-1.z-index, .home-tooltip__carousel-item-content .tooltip-2.z-index, .home-tooltip__carousel-item-content .tooltip-3.z-index {
    z-index: 1; }

.home-tooltip__carousel-item-content .tooltip-img {
  display: block; }

.home-tooltip__carousel-item-content .largetooltip-img {
  display: none; }
  @media (min-width: 1920px) {
    .home-tooltip__carousel-item-content .largetooltip-img {
      display: block; } }

.home-tooltip__carousel-item-content .white-tooltip {
  display: none !important;
  position: absolute;
  left: 0px;
  top: 1px; }

.home-tooltip__carousel-item-content .white-tooltip-large {
  display: none !important;
  position: absolute;
  left: 0px;
  top: 0px; }

.home-tooltip__carousel-item-content .tooltip-1 .tooltip-content, .home-tooltip__carousel-item-content .tooltip-2 .tooltip-content, .home-tooltip__carousel-item-content .tooltip-3 .tooltip-content {
  display: none; }

.home-tooltip__carousel-item-content .tooltip-1:hover, .home-tooltip__carousel-item-content .tooltip-1:focus, .home-tooltip__carousel-item-content .tooltip-2:hover, .home-tooltip__carousel-item-content .tooltip-2:focus, .home-tooltip__carousel-item-content .tooltip-3:hover, .home-tooltip__carousel-item-content .tooltip-3:focus {
  cursor: pointer; }
  @media (min-width: 1280px) {
    .home-tooltip__carousel-item-content .tooltip-1:hover > .white-tooltip, .home-tooltip__carousel-item-content .tooltip-1:focus > .white-tooltip, .home-tooltip__carousel-item-content .tooltip-2:hover > .white-tooltip, .home-tooltip__carousel-item-content .tooltip-2:focus > .white-tooltip, .home-tooltip__carousel-item-content .tooltip-3:hover > .white-tooltip, .home-tooltip__carousel-item-content .tooltip-3:focus > .white-tooltip {
      display: inline !important; } }
  @media (min-width: 1280px) {
    .home-tooltip__carousel-item-content .tooltip-1:hover > .tooltip-img, .home-tooltip__carousel-item-content .tooltip-1:focus > .tooltip-img, .home-tooltip__carousel-item-content .tooltip-2:hover > .tooltip-img, .home-tooltip__carousel-item-content .tooltip-2:focus > .tooltip-img, .home-tooltip__carousel-item-content .tooltip-3:hover > .tooltip-img, .home-tooltip__carousel-item-content .tooltip-3:focus > .tooltip-img {
      visibility: hidden; } }

.home-tooltip__carousel-item-content .tooltip-img.hide {
  visibility: hidden; }

.home-tooltip__carousel-item-content .tooltip-img.show {
  display: block; }

.home-tooltip__carousel-item-content .white-tooltip.hide {
  display: none !important; }

.home-tooltip__carousel-item-content .white-tooltip.show {
  display: inline !important; }

@media (min-width: 1280px) {
  .home-tooltip__carousel-item-content .tooltip-1-content, .home-tooltip__carousel-item-content .tooltip-2-content, .home-tooltip__carousel-item-content .tooltip-3-content {
    width: 295px;
    padding: 22px 16px 17px 17px;
    border-radius: 6px;
    background-color: #ffffff;
    z-index: 10; } }

@media (min-width: 1920px) {
  .home-tooltip__carousel-item-content .tooltip-1-content, .home-tooltip__carousel-item-content .tooltip-2-content, .home-tooltip__carousel-item-content .tooltip-3-content {
    width: 401px;
    border-radius: 8px;
    padding: 37px 20px 20px; } }

@media (min-width: 1280px) {
  .home-tooltip__carousel-item-content .tooltip-1-content {
    bottom: -15px;
    right: 23px; } }

@media (min-width: 1920px) {
  .home-tooltip__carousel-item-content .tooltip-1-content {
    right: 41px; } }

@media (min-width: 1280px) {
  .home-tooltip__carousel-item-content .tooltip-3-content {
    right: -140px;
    bottom: 24px; } }

@media (min-width: 1920px) {
  .home-tooltip__carousel-item-content .tooltip-3-content {
    bottom: 35px; } }

@media (min-width: 1280px) {
  .home-tooltip__carousel-item-content .tooltip-2-content {
    right: -65px;
    bottom: 24px; } }

@media (min-width: 1920px) {
  .home-tooltip__carousel-item-content .tooltip-2-content {
    right: -100px;
    bottom: 35px; } }

@media (min-width: 1280px) {
  .home-tooltip__carousel-item-content .tooltip-title {
    font-family: 'Butler-Light';
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    text-transform: uppercase; } }

@media (min-width: 1920px) {
  .home-tooltip__carousel-item-content .tooltip-title {
    font-size: 30px; } }

@media (min-width: 1280px) {
  .home-tooltip__carousel-item-content .tooltip-description {
    font-family: 'Archivo-Light';
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.36px;
    text-align: center;
    color: #292929;
    margin-top: 13px; } }

@media (min-width: 1920px) {
  .home-tooltip__carousel-item-content .tooltip-description {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: 0.48px; } }

.home-tooltip__carousel-item-content .tooltip-cta:hover {
  text-decoration: none;
  color: #ffffff; }

@media (min-width: 1280px) {
  .home-tooltip__carousel-item-content .tooltip-cta {
    width: 262px;
    height: 41px;
    border-radius: 6px;
    background-color: #000;
    opacity: 1;
    font-family: 'Archivo-Medium';
    font-size: 9px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.56;
    letter-spacing: 1.58px;
    text-align: center;
    color: #ffffff;
    padding: 18px 0;
    display: block;
    margin-top: 18px;
    text-transform: uppercase; } }

@media (min-width: 1920px) {
  .home-tooltip__carousel-item-content .tooltip-cta {
    width: 361px;
    height: 60px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 2.11px;
    margin-top: 16px;
    padding: 24px 0; } }

.tooltip-img,
.white-tooltip {
  width: 22px;
  height: 22px; }

@media screen and (min-width: 1920px) {
  .tooltip-img,
  .white-tooltip {
    width: 33px;
    height: 33px; } }

.home-tooltip-image {
  padding-bottom: 100%; }
  @media (min-width: 768px) {
    .home-tooltip-image {
      padding-bottom: 108.855%; } }
  @media (min-width: 1280px) {
    .home-tooltip-image {
      padding-bottom: 77.189%; } }

.tooltip-1, .tooltip-2, .tooltip-3 {
  display: block;
  background: url("../images/icons/hotshot.svg") center center no-repeat;
  position: absolute;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
  background-size: 90%; }
  .tooltip-1.focused, .tooltip-2.focused, .tooltip-3.focused {
    background: url("../images/icons/hotshot-hover.svg") center center no-repeat;
    position: absolute;
    background-size: 90%; }

.popover-hotspot {
  max-width: none;
  border-radius: 6px;
  border: none;
  margin: 0 !important; }
  .popover-hotspot .arrow {
    display: none; }
  .popover-hotspot .bs-popover-right, .popover-hotspot .bs-popover-right, .popover-hotspot .bs-popover-top, .popover-hotspot .bs-popover-bottom {
    margin: 0 !important; }
  @media (min-width: 1280px) {
    .popover-hotspot .popover-body {
      width: 295px;
      padding: 22px 16px 17px 17px;
      border-radius: 6px;
      background-color: #ffffff;
      z-index: 10; } }
  @media (min-width: 1920px) {
    .popover-hotspot .popover-body {
      width: 401px;
      border-radius: 8px;
      padding: 37px 20px 20px; } }
  @media (min-width: 1280px) {
    .popover-hotspot .popover-body .tooltip-title, .popover-hotspot .popover-body .tooltip-1-title, .popover-hotspot .popover-body .tooltip-2-title, .popover-hotspot .popover-body .tooltip-3-title {
      font-family: 'Butler-Light';
      font-size: 24px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      text-transform: uppercase; } }
  @media (min-width: 1920px) {
    .popover-hotspot .popover-body .tooltip-title, .popover-hotspot .popover-body .tooltip-1-title, .popover-hotspot .popover-body .tooltip-2-title, .popover-hotspot .popover-body .tooltip-3-title {
      font-size: 30px; } }
  @media (min-width: 1280px) {
    .popover-hotspot .popover-body .tooltip-description, .popover-hotspot .popover-body .tooltip-1-description, .popover-hotspot .popover-body .tooltip-2-description, .popover-hotspot .popover-body .tooltip-3-description {
      font-family: 'Archivo-Light';
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: 0.36px;
      text-align: center;
      color: #292929;
      margin-top: 13px; } }
  @media (min-width: 1920px) {
    .popover-hotspot .popover-body .tooltip-description, .popover-hotspot .popover-body .tooltip-1-description, .popover-hotspot .popover-body .tooltip-2-description, .popover-hotspot .popover-body .tooltip-3-description {
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: 0.48px; } }
  .popover-hotspot .popover-body .tooltip-cta:hover {
    text-decoration: none;
    color: #ffffff; }
  @media (min-width: 1280px) {
    .popover-hotspot .popover-body .tooltip-cta {
      width: 262px;
      height: 41px;
      border-radius: 6px;
      background-color: #000;
      opacity: 1;
      font-family: 'Archivo-Medium';
      font-size: 9px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.56;
      letter-spacing: 1.58px;
      text-align: center;
      color: #ffffff;
      padding: 18px 0;
      display: block;
      margin-top: 18px;
      text-transform: uppercase; } }
  @media (min-width: 1920px) {
    .popover-hotspot .popover-body .tooltip-cta {
      width: 361px;
      height: 60px;
      border-radius: 8px;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 2.11px;
      margin-top: 16px;
      padding: 24px 0; } }

.explore-collection {
  margin: 61px 20px 0; }
  @media (min-width: 768px) {
    .explore-collection {
      margin: 64px 20px 0; } }
  @media (min-width: 1280px) {
    .explore-collection {
      margin: 49px 26px 0 27px; } }
  @media (min-width: 1920px) {
    .explore-collection {
      margin: 73px 41px 0; } }

@media (max-width: 767.98px) {
  .explore-collection__info {
    display: block !important; } }

.explore-collection__section {
  margin-bottom: 76px; }
  @media (min-width: 768px) {
    .explore-collection__section {
      margin-bottom: 50.5px; } }
  @media (min-width: 1280px) {
    .explore-collection__section {
      position: relative;
      margin-bottom: 51.5px; } }
  @media (min-width: 1920px) {
    .explore-collection__section {
      margin-bottom: 75.5px; } }
  @media (max-width: 767.98px) {
    .explore-collection__section:last-child {
      margin-bottom: 64.5px; } }

.explore-collection__title {
  font-family: 'Archivo-ExtraLight';
  font-size: 16px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: 2.4px;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  margin: 0 0 49px; }
  @media (min-width: 768px) {
    .explore-collection__title {
      margin: 0 0 64px; } }
  @media (min-width: 1280px) {
    .explore-collection__title {
      margin: 0 0 49px; } }
  @media (min-width: 1920px) {
    .explore-collection__title {
      font-size: 20px;
      line-height: 1.55;
      letter-spacing: 3px;
      margin: 0 0 60px; } }

@media (min-width: 768px) {
  .explore-collection__section:nth-child(2) {
    margin: 0 16px 50.5px; } }

@media (min-width: 1280px) {
  .explore-collection__section:nth-child(2) {
    margin: 0 27px 51.5px; } }

@media (min-width: 1920px) {
  .explore-collection__section:nth-child(2) {
    margin: 0 40px 75.5px; } }

@media (min-width: 1280px) {
  .explore-collection__content {
    position: absolute;
    bottom: 18px;
    left: 17px; } }

@media (min-width: 1920px) {
  .explore-collection__content {
    bottom: 27px;
    left: 26px; } }

.explore-collection__content--title {
  font-family: 'Butler-Light';
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: 0.64px;
  text-align: left;
  color: #000;
  text-transform: uppercase;
  margin-top: 29px; }
  @media (min-width: 768px) {
    .explore-collection__content--title {
      font-size: 24px;
      line-height: 1.58;
      letter-spacing: 0.48px;
      margin-top: 25px; } }
  @media (min-width: 1280px) {
    .explore-collection__content--title {
      font-size: 28px;
      line-height: 1.36;
      letter-spacing: 0.56px;
      color: #ffffff;
      margin-top: 0; } }
  @media (min-width: 1920px) {
    .explore-collection__content--title {
      font-size: 35px;
      line-height: 1.09;
      letter-spacing: 0.7px; } }

.explore-collection__content--description {
  font-family: 'Archivo-ExtraLight';
  font-size: 16px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.8px;
  text-align: left;
  color: #000;
  margin-top: 14px; }
  @media (min-width: 768px) {
    .explore-collection__content--description {
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.36px;
      margin-top: 13px;
      margin-right: 20px; } }
  @media (min-width: 1280px) {
    .explore-collection__content--description {
      font-family: 'Archivo-Light';
      color: #ffffff;
      margin-top: 10px;
      margin-right: 90px; } }
  @media (min-width: 1920px) {
    .explore-collection__content--description {
      font-size: 16px;
      line-height: 1.44;
      letter-spacing: 0.48px;
      margin-top: 13px;
      margin-right: 226px; } }

.explore-collection__content--cta {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  background-color: #000;
  font-family: 'Archivo-Medium';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 2.11px;
  text-align: center;
  color: #ffffff;
  display: block;
  padding: 24px 0;
  margin-top: 24px;
  text-transform: uppercase; }
  .explore-collection__content--cta:hover {
    text-decoration: none;
    color: #ffffff; }
    @media (min-width: 1280px) {
      .explore-collection__content--cta:hover {
        color: #000; } }
  @media (min-width: 768px) {
    .explore-collection__content--cta {
      width: 100%;
      height: 41px;
      border-radius: 6px;
      font-size: 9px;
      line-height: 0.56;
      letter-spacing: 1.58px;
      margin-top: 21px;
      padding: 18px 0; } }
  @media (min-width: 1280px) {
    .explore-collection__content--cta {
      min-width: 119px;
      width: auto;
      height: 37px;
      border-radius: 6px;
      background-color: #ffffff;
      color: #000;
      margin-top: 12px;
      padding: 16px 22px 16px 21px;
      display: inline-block; } }
  @media (min-width: 1920px) {
    .explore-collection__content--cta {
      min-width: 153px;
      width: auto;
      height: 40px;
      border-radius: 8px;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 2.11px;
      margin-top: 28px;
      padding: 14px 40px; } }

.explore-collection__img {
  position: relative; }
  .explore-collection__img:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: #000;
    opacity: 0.4;
    border-radius: 8px; }
    @media (min-width: 768px) {
      .explore-collection__img:after {
        border-radius: 6px; } }
    @media (min-width: 1280px) {
      .explore-collection__img:after {
        border-radius: 6px; } }
    @media (min-width: 1920px) {
      .explore-collection__img:after {
        border-radius: 8px; } }
  .explore-collection__img img {
    border-radius: 8px; }
    @media (min-width: 768px) {
      .explore-collection__img img {
        border-radius: 6px; } }
    @media (min-width: 1280px) {
      .explore-collection__img img {
        border-radius: 6px; } }
    @media (min-width: 1920px) {
      .explore-collection__img img {
        border-radius: 8px; } }

.explore-collection__link {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.home__section-5 {
  margin: 0 0 0 20px;
  padding: 0 0 64px 0; }
  @media (min-width: 768px) {
    .home__section-5 {
      border-top: 1px solid #eaeaea;
      padding: 50.5px 0;
      margin: 0 20px;
      position: relative; } }
  @media (min-width: 1280px) {
    .home__section-5 {
      padding: 51.5px 0;
      margin: 0 26px; } }
  @media (min-width: 1920px) {
    .home__section-5 {
      padding: 75.5px 0;
      margin: 0 41px; } }

.home-tooltip-swatch__carousel .slick-prev {
  background: url("../images/icons/left-arrow-icon.svg") center center no-repeat;
  background-size: cover;
  width: 15px;
  height: 27px;
  opacity: 1; }
  @media (min-width: 1280px) {
    .home-tooltip-swatch__carousel .slick-prev {
      top: 47.3%;
      color: #ffffff;
      left: 29px; } }
  @media (min-width: 1920px) {
    .home-tooltip-swatch__carousel .slick-prev {
      width: 22px;
      height: 41px;
      left: 44px; } }
  .home-tooltip-swatch__carousel .slick-prev::before {
    display: none; }

.home-tooltip-swatch__carousel .slick-next {
  background: url("../images/icons/right-arrow-icon.svg") center center no-repeat;
  background-size: cover;
  width: 15px;
  height: 27px;
  opacity: 1; }
  @media (min-width: 1280px) {
    .home-tooltip-swatch__carousel .slick-next {
      top: 47.3%;
      color: #ffffff;
      right: 29px; } }
  @media (min-width: 1920px) {
    .home-tooltip-swatch__carousel .slick-next {
      width: 22px;
      height: 41px;
      right: 44px; } }
  .home-tooltip-swatch__carousel .slick-next::before {
    display: none; }

.home-tooltip-swatch {
  z-index: 1; }
  @media (min-width: 768px) {
    .home-tooltip-swatch {
      position: relative; } }
  .home-tooltip-swatch img {
    border-radius: 8px; }
    @media (min-width: 768px) {
      .home-tooltip-swatch img {
        border-radius: 6px; } }
    @media (min-width: 1280px) {
      .home-tooltip-swatch img {
        border-radius: 6px; } }
    @media (min-width: 1920px) {
      .home-tooltip-swatch img {
        border-radius: 8px; } }

@media (max-width: 767.98px) {
  .home-tooltip-swatch__thumbs {
    max-width: 100%; } }

@media (max-width: 767.98px) {
  .home-tooltip-swatch__thumbs .slick-list {
    padding: 0 47px 0 0 !important; } }

@media (max-width: 767.98px) {
  .home-tooltip-swatch__thumbs .home-tooltip-swatch__carousel-item {
    padding-right: 11px; } }

@media (max-width: 767.98px) {
  .home-tooltip-swatch__thumbs .slick-prev {
    display: none; }
  .home-tooltip-swatch__thumbs .slick-next {
    position: absolute;
    top: calc((100% - 27px)/2);
    right: 15px;
    left: auto;
    background: url("../images/icons/slider-right-arrow.svg") center center no-repeat;
    background-size: cover;
    width: 15px;
    height: 27px; }
    .home-tooltip-swatch__thumbs .slick-next::before {
      display: none; } }

.home-tooltip-swatch__info {
  max-width: 100%;
  margin: auto; }
  @media (min-width: 768px) {
    .home-tooltip-swatch__info {
      position: absolute;
      top: 72px; } }
  @media (min-width: 1280px) {
    .home-tooltip-swatch__info {
      top: 84px; } }
  @media (min-width: 1920px) {
    .home-tooltip-swatch__info {
      top: 126px; } }

@media (max-width: 767.98px) {
  .home-tooltip-swatch__carousel {
    max-width: 100%;
    display: none; } }

@media (max-width: 767.98px) {
  .home-tooltip-swatch__carousel .slick-list {
    padding: 0 47px 0 0 !important; } }

.home-tooltip-swatch__carousel .slick-dots {
  display: flex;
  margin-bottom: 0;
  bottom: -20px; }
  @media (max-width: 1279.98px) {
    .home-tooltip-swatch__carousel .slick-dots {
      display: none !important; } }
  .home-tooltip-swatch__carousel .slick-dots button:after {
    width: 7px;
    height: 7px;
    opacity: 1;
    background: #757575; }
    @media (min-width: 1920px) {
      .home-tooltip-swatch__carousel .slick-dots button:after {
        width: 9px;
        height: 9px; } }
  .home-tooltip-swatch__carousel .slick-dots .slick-active button:after {
    background: #000000; }

@media (max-width: 1279.98px) {
  .home-tooltip-image__carousel-tooltip {
    display: none; } }

.home-tooltip-image__carousel-tooltip .tooltip-1, .home-tooltip-image__carousel-tooltip .tooltip-2, .home-tooltip-image__carousel-tooltip .tooltip-3 {
  z-index: 2; }
  .home-tooltip-image__carousel-tooltip .tooltip-1.z-index, .home-tooltip-image__carousel-tooltip .tooltip-2.z-index, .home-tooltip-image__carousel-tooltip .tooltip-3.z-index {
    z-index: 1; }

.home-tooltip-image__carousel-tooltip .tooltip-img {
  display: block; }

.home-tooltip-image__carousel-tooltip .largetooltip-img {
  display: none; }
  @media (min-width: 1920px) {
    .home-tooltip-image__carousel-tooltip .largetooltip-img {
      display: block; } }

.home-tooltip-image__carousel-tooltip .white-tooltip {
  display: none !important;
  position: absolute;
  left: 0px;
  top: 1px; }

.home-tooltip-image__carousel-tooltip .white-tooltip-large {
  display: none !important;
  position: absolute;
  left: 0px;
  top: 0px; }

.home-tooltip-image__carousel-tooltip .tooltip-1 .tooltip-content, .home-tooltip-image__carousel-tooltip .tooltip-2 .tooltip-content, .home-tooltip-image__carousel-tooltip .tooltip-3 .tooltip-content {
  display: none; }

.home-tooltip-image__carousel-tooltip .tooltip-1:hover, .home-tooltip-image__carousel-tooltip .tooltip-1:focus, .home-tooltip-image__carousel-tooltip .tooltip-2:hover, .home-tooltip-image__carousel-tooltip .tooltip-2:focus, .home-tooltip-image__carousel-tooltip .tooltip-3:hover, .home-tooltip-image__carousel-tooltip .tooltip-3:focus {
  cursor: pointer; }
  @media (min-width: 1280px) {
    .home-tooltip-image__carousel-tooltip .tooltip-1:hover > .white-tooltip, .home-tooltip-image__carousel-tooltip .tooltip-1:focus > .white-tooltip, .home-tooltip-image__carousel-tooltip .tooltip-2:hover > .white-tooltip, .home-tooltip-image__carousel-tooltip .tooltip-2:focus > .white-tooltip, .home-tooltip-image__carousel-tooltip .tooltip-3:hover > .white-tooltip, .home-tooltip-image__carousel-tooltip .tooltip-3:focus > .white-tooltip {
      display: inline !important; } }
  @media (min-width: 1280px) {
    .home-tooltip-image__carousel-tooltip .tooltip-1:hover > .tooltip-img, .home-tooltip-image__carousel-tooltip .tooltip-1:focus > .tooltip-img, .home-tooltip-image__carousel-tooltip .tooltip-2:hover > .tooltip-img, .home-tooltip-image__carousel-tooltip .tooltip-2:focus > .tooltip-img, .home-tooltip-image__carousel-tooltip .tooltip-3:hover > .tooltip-img, .home-tooltip-image__carousel-tooltip .tooltip-3:focus > .tooltip-img {
      visibility: hidden; } }

.home-tooltip-image__carousel-tooltip .tooltip-img.hide {
  visibility: hidden; }

.home-tooltip-image__carousel-tooltip .tooltip-img.show {
  display: block; }

.home-tooltip-image__carousel-tooltip .white-tooltip.hide {
  display: none !important; }

.home-tooltip-image__carousel-tooltip .white-tooltip.show {
  display: inline !important; }

.home-tooltip-image__carousel-tooltip .tooltip-1-image, .home-tooltip-image__carousel-tooltip .tooltip-2-image, .home-tooltip-image__carousel-tooltip .tooltip-3-image {
  z-index: 1;
  position: relative; }

@media (min-width: 1280px) {
  .home-tooltip-image__carousel-tooltip .tooltip-1-content, .home-tooltip-image__carousel-tooltip .tooltip-2-content, .home-tooltip-image__carousel-tooltip .tooltip-3-content {
    width: 295px;
    padding: 22px 16px 17px 17px;
    border-radius: 6px;
    background-color: #ffffff;
    z-index: 10; } }

@media (min-width: 1920px) {
  .home-tooltip-image__carousel-tooltip .tooltip-1-content, .home-tooltip-image__carousel-tooltip .tooltip-2-content, .home-tooltip-image__carousel-tooltip .tooltip-3-content {
    width: 401px;
    border-radius: 8px;
    padding: 37px 20px 20px; } }

@media (min-width: 1280px) {
  .home-tooltip-image__carousel-tooltip .tooltip-1-content {
    bottom: -35px;
    left: 22px; } }

@media (min-width: 1920px) {
  .home-tooltip-image__carousel-tooltip .tooltip-1-content {
    left: 41px; } }

@media (min-width: 1280px) {
  .home-tooltip-image__carousel-tooltip .tooltip-3-content {
    right: -140px;
    bottom: 24px; } }

@media (min-width: 1920px) {
  .home-tooltip-image__carousel-tooltip .tooltip-3-content {
    bottom: 35px; } }

@media (min-width: 1280px) {
  .home-tooltip-image__carousel-tooltip .tooltip-2-content {
    right: -140px;
    bottom: 24px; } }

@media (min-width: 1920px) {
  .home-tooltip-image__carousel-tooltip .tooltip-2-content {
    bottom: 35px; } }

@media (min-width: 1280px) {
  .home-tooltip-image__carousel-tooltip .tooltip-1-title, .home-tooltip-image__carousel-tooltip .tooltip-2-title, .home-tooltip-image__carousel-tooltip .tooltip-3-title {
    font-family: 'Butler-Light';
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    text-transform: uppercase; } }

@media (min-width: 1920px) {
  .home-tooltip-image__carousel-tooltip .tooltip-1-title, .home-tooltip-image__carousel-tooltip .tooltip-2-title, .home-tooltip-image__carousel-tooltip .tooltip-3-title {
    font-size: 30px; } }

@media (min-width: 1280px) {
  .home-tooltip-image__carousel-tooltip .tooltip-1-description, .home-tooltip-image__carousel-tooltip .tooltip-2-description, .home-tooltip-image__carousel-tooltip .tooltip-3-description {
    font-family: 'Archivo-Light';
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.36px;
    text-align: center;
    color: #292929;
    margin-top: 13px; } }

@media (min-width: 1920px) {
  .home-tooltip-image__carousel-tooltip .tooltip-1-description, .home-tooltip-image__carousel-tooltip .tooltip-2-description, .home-tooltip-image__carousel-tooltip .tooltip-3-description {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: 0.48px; } }

.home-tooltip-image__carousel-tooltip .tooltip-cta:hover, .home-tooltip-image__carousel-tooltip .tooltip-1-cta:hover, .home-tooltip-image__carousel-tooltip .tooltip-2-cta:hover, .home-tooltip-image__carousel-tooltip .tooltip-3-cta:hover {
  text-decoration: none; }

@media (min-width: 1280px) {
  .home-tooltip-image__carousel-tooltip .tooltip-cta, .home-tooltip-image__carousel-tooltip .tooltip-1-cta, .home-tooltip-image__carousel-tooltip .tooltip-2-cta, .home-tooltip-image__carousel-tooltip .tooltip-3-cta {
    width: 262px;
    height: 41px;
    border-radius: 6px;
    background-color: #000;
    opacity: 1;
    font-family: 'Archivo-Medium';
    font-size: 9px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.56;
    letter-spacing: 1.58px;
    text-align: center;
    color: #ffffff;
    padding: 17px 0;
    display: block;
    margin-top: 18px;
    text-transform: uppercase; } }

@media (min-width: 1920px) {
  .home-tooltip-image__carousel-tooltip .tooltip-cta, .home-tooltip-image__carousel-tooltip .tooltip-1-cta, .home-tooltip-image__carousel-tooltip .tooltip-2-cta, .home-tooltip-image__carousel-tooltip .tooltip-3-cta {
    width: 361px;
    height: 60px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 2.11px;
    margin-top: 16px;
    padding: 24px 0; } }

@media (max-width: 767.98px) {
  .home-tooltip-image__carousel-item {
    padding-right: 11px; } }

@media (min-width: 768px) {
  .home-tooltip-swatch__carousel-item {
    cursor: pointer;
    padding-right: 9px; } }

@media (min-width: 1280px) {
  .home-tooltip-swatch__carousel-item {
    padding-right: 16px; } }

@media (min-width: 1920px) {
  .home-tooltip-swatch__carousel-item {
    padding-right: 24px; } }

@media (min-width: 1280px) {
  .home-tooltip-swatch__carousel-item:last-child {
    padding-right: 18px; } }

@media (min-width: 1920px) {
  .home-tooltip-swatch__carousel-item:last-child {
    padding-right: 27px; } }

.home-tooltip-swatch__carousel-item img {
  border-radius: 8px; }
  @media (min-width: 768px) {
    .home-tooltip-swatch__carousel-item img {
      transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
      border-radius: 6px; } }
  @media (min-width: 1920px) {
    .home-tooltip-swatch__carousel-item img {
      border-radius: 8px; } }
  @media (min-width: 1280px) {
    .home-tooltip-swatch__carousel-item img:hover {
      cursor: pointer;
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);
      transform: scale3d(1.01, 1.01, 1.01); } }

.home-shop-collection__info {
  z-index: 10; }
  @media (min-width: 768px) {
    .home-shop-collection__info {
      text-align: center;
      position: absolute;
      width: 50%;
      right: 0;
      top: 122px; } }
  @media (min-width: 1280px) {
    .home-shop-collection__info {
      top: 135px;
      right: auto; } }
  @media (min-width: 1920px) {
    .home-shop-collection__info {
      top: 201px; } }

.home-tooltip-swatch__content--title {
  font-family: 'Butler-Light';
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 17px; }
  @media (min-width: 768px) {
    .home-tooltip-swatch__content--title {
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 18px; } }
  @media (min-width: 1920px) {
    .home-tooltip-swatch__content--title {
      font-size: 30px;
      line-height: 1;
      margin-bottom: 44px; } }

.home-tooltip-swatch__content--description {
  font-family: 'Archivo-ExtraLight';
  font-size: 16px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.48px;
  text-align: center;
  color: #292929;
  margin-bottom: 28.5px;
  padding: 0 40px; }
  @media (min-width: 768px) {
    .home-tooltip-swatch__content--description {
      font-family: 'Archivo-Light';
      font-size: 12px;
      font-weight: 300;
      line-height: 1.42;
      letter-spacing: 0.36px;
      margin: 0 auto 17px;
      width: 239px;
      padding: 0; } }
  @media (min-width: 1920px) {
    .home-tooltip-swatch__content--description {
      font-size: 16px;
      line-height: 1.25;
      letter-spacing: 0.48px;
      margin: 0 auto 34px;
      width: 359px; } }

.home-tooltip-swatch__content--cta {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  background-color: #000;
  font-family: 'Archivo-Medium';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 2.11px;
  text-align: center;
  color: #ffffff;
  padding: 24px 0;
  display: block;
  text-transform: uppercase;
  margin: auto; }
  .home-tooltip-swatch__content--cta:hover {
    text-decoration: none; }
  .home-tooltip-swatch__content--cta:focus {
    outline: 2px solid #007bff; }
  @media (min-width: 768px) {
    .home-tooltip-swatch__content--cta {
      min-width: 169px;
      width: auto;
      height: 41px;
      border-radius: 6px;
      opacity: 1;
      font-size: 9px;
      line-height: 0.56;
      letter-spacing: 1.58px;
      padding: 19px 15px;
      display: inline-block; } }
  @media (min-width: 1920px) {
    .home-tooltip-swatch__content--cta {
      min-width: 225px;
      height: 60px;
      border-radius: 8px;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 2.11px;
      padding: 24px 22px; } }
  .home-tooltip-swatch__content--cta:hover {
    color: #ffffff; }

@media (max-width: 767.98px) {
  .home-shop-collection__info {
    border-top: 1px solid #eaeaea;
    margin-right: 20px;
    padding: 64px 0 64.5px; } }

@media (min-width: 768px) and (max-width: 1279.98px) {
  .home-tooltip-section {
    flex-direction: row-reverse; }
  .home-tooltip-swatch__carousel-item {
    padding-left: 9px;
    padding-right: 0; } }

.home-tooltip-image-carousel {
  padding-bottom: 85.86%; }
  @media (min-width: 768px) {
    .home-tooltip-image-carousel {
      padding-bottom: 105.495%; } }
  @media (min-width: 1280px) {
    .home-tooltip-image-carousel {
      padding-bottom: 75.245%; } }
  @media (min-width: 1920px) {
    .home-tooltip-image-carousel {
      padding-bottom: 75.3%; } }

.discover-services__title, .discover-services__content--title {
  font-family: "Butler";
  margin: 0;
  color: #212529;
  font-weight: 400; }

.discover-services {
  background: #efede9;
  padding: 40px 0;
  margin: 0 0 64px; }

.discover-services__title {
  margin-top: 48px;
  margin-bottom: 0;
  font-size: 24px;
  line-height: 1.25; }

.discover-services__content {
  flex: 1 1 33%;
  position: relative;
  padding: 48px 0;
  margin: 0 48px;
  gap: 28px; }
  .discover-services__content:not(:last-child) {
    border-bottom: 1px solid #bcbcbc; }

.discover-services__content--title {
  font-family: "Archivo";
  font-size: 20px;
  line-height: 1.25;
  letter-spacing: 0.02em; }

.discover-services__content--description {
  font-family: "Archivo";
  font-weight: 400;
  color: #71767c;
  font-size: 14px;
  line-height: 1.45;
  letter-spacing: 0.03em; }

.discover-services__content--cta {
  text-decoration: underline;
  min-height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 32px;
  font-family: "Archivo";
  font-size: 14px;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.01em;
  color: #5c5c5c; }
  .discover-services__content--cta:hover, .discover-services__content--cta:active {
    text-decoration: none; }

@media (min-width: 1200px) {
  margin: 0 0 48px;
  .discover-services {
    padding: 24px 48px 32px;
    max-width: 1200px -96px; }
  .discover-services__title {
    margin-bottom: 12px;
    font-size: 26px; }
  .discover-services__content {
    margin: 48px 0;
    padding: 0 64px; }
    .discover-services__content:not(:last-child) {
      border-bottom: none;
      border-right: 1px solid #bcbcbc; } }

.h-by-frette {
  background-color: #cdb98e;
  padding: 20px;
  margin-top: -23px;
  margin-bottom: 11px; }
  @media (min-width: 768px) {
    .h-by-frette {
      background-color: #ffffff;
      padding: 0;
      margin-top: 49px;
      margin-bottom: 52px; } }
  @media (min-width: 1280px) {
    .h-by-frette {
      margin-bottom: 49px;
      margin-bottom: 46px; } }
  @media (min-width: 1920px) {
    .h-by-frette {
      margin-top: 64px;
      margin-bottom: 58px; } }

.h-by-frette__section {
  flex-direction: column; }
  @media (min-width: 768px) {
    .h-by-frette__section {
      flex-direction: row; } }

.h-by-frette__info {
  background-color: #cdb98e;
  width: 100%;
  order: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 28px; }
  @media (min-width: 768px) {
    .h-by-frette__info {
      width: 50%;
      order: 1;
      align-items: center;
      padding-top: 0; } }
  @media (min-width: 1280px) {
    .h-by-frette__info {
      width: 37.5%; } }

.h-by-frette__img {
  width: 100%;
  order: 1; }
  @media (min-width: 768px) {
    .h-by-frette__img {
      width: 50%;
      order: 2; } }
  @media (min-width: 1280px) {
    .h-by-frette__img {
      width: 62.5%; } }

.h-by-frette__logo {
  width: 68px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 1920px) {
    .h-by-frette__logo {
      width: 102px; } }

.h-by-frette__description {
  font-family: 'Archivo-ExtraLight';
  font-size: 16px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.48px;
  text-align: center;
  color: #000;
  margin: 34px auto 30px;
  width: 277px; }
  @media (min-width: 768px) {
    .h-by-frette__description {
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.36px;
      margin: 33px auto 30px;
      width: 207px; } }
  @media (min-width: 1280px) {
    .h-by-frette__description {
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.42px;
      margin: 38px auto 35px;
      width: 308px; } }
  @media (min-width: 1920px) {
    .h-by-frette__description {
      font-size: 20px;
      line-height: 1.55;
      letter-spacing: 0.6px;
      margin: 58px auto 55px;
      width: 462px; } }

.h-by-frette__cta {
  font-family: 'Archivo-Medium';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 2.11px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 8px;
  background-color: #031d30;
  width: 100%;
  height: 60px;
  display: inline-block;
  padding: 24px 0; }
  @media (min-width: 768px) {
    .h-by-frette__cta {
      font-size: 9px;
      line-height: 0.56;
      letter-spacing: 1.58px;
      width: 165px;
      height: 41px;
      padding: 17px 0; } }
  @media (min-width: 1920px) {
    .h-by-frette__cta {
      width: 243px;
      height: 60px;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 2.11px;
      padding: 24px 0; } }
  .h-by-frette__cta:hover, .h-by-frette__cta:active {
    color: #ffffff;
    text-decoration: none; }

.open-link-icon {
  margin-bottom: 4px; }
  @media (min-width: 768px) {
    .open-link-icon {
      margin-bottom: 3px; } }
  @media (min-width: 1920px) {
    .open-link-icon {
      margin-bottom: 4px; } }

.frette-loves-design__section {
  margin: 0 20px;
  padding: 40px 0 64px; }
  @media (min-width: 1200px) {
    .frette-loves-design__section {
      padding: 40px 0; } }

.frette-loves-design__title {
  font-family: 'Archivo-ExtraLight';
  font-size: 20px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: 3px;
  text-align: center;
  color: #000; }
  @media (min-width: 768px) {
    .frette-loves-design__title {
      font-size: 16px;
      line-height: 1.94;
      letter-spacing: 2.4px; } }
  @media (min-width: 1920px) {
    .frette-loves-design__title {
      font-size: 20px;
      line-height: 1.55;
      letter-spacing: 3px; } }
