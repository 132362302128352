//H By Frette Homepage A/B Testing
.h-by-frette {
    background-color: #cdb98e;
    padding: 20px;
    margin-top: -23px;
    margin-bottom: 11px;
    @media (min-width: 768px) {
        background-color: $white;
        padding: 0;
        margin-top: 49px;
        margin-bottom: 52px;
    }
    @media (min-width: 1280px) {
        margin-bottom: 49px;
        margin-bottom: 46px;
    }
    @media (min-width: 1920px) {
        margin-top: 64px;
        margin-bottom: 58px;
    }
}

.h-by-frette__section {
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    @media (min-width: 1280px) {
    }
    @media (min-width: 1920px) {
    
    }
}

.h-by-frette__info {
    background-color: #cdb98e;
    width: 100%;
    order: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 28px;

    @media (min-width: 768px) {
        width: 50%;
        order: 1;
        align-items: center;
        padding-top: 0;
    }
    @media (min-width: 1280px) {
        width: 37.5%;
    }
    @media (min-width: 1920px) {
    
    }
}

.h-by-frette__img {
    width: 100%;
    order: 1;
    @media (min-width: 768px) {
        width: 50%;
        order: 2;
    }
    @media (min-width: 1280px) {
        width: 62.5%;
    }
    @media (min-width: 1920px) {
    
    }
}

.h-by-frette__logo {
    width: 68px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 768px) {
        
    }
    @media (min-width: 1280px) {
    }
    @media (min-width: 1920px) {
        width: 102px;
    }
}

.h-by-frette__description {
    font-family: 'Archivo-ExtraLight';
    font-size: 16px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: 0.48px;
    text-align: center;
    color: $black;
    margin: 34px auto 30px;
    width: 277px;
    @media (min-width: 768px) {
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 0.36px;
        margin: 33px auto 30px;
        width: 207px;
    }
    @media (min-width: 1280px) {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.42px;
        margin: 38px auto 35px;
        width: 308px;
    }
    @media (min-width: 1920px) {
        font-size: 20px;
        line-height: 1.55;
        letter-spacing: 0.6px;
        margin: 58px auto 55px;
        width: 462px;
    }
}

.h-by-frette__cta {
    font-family: 'Archivo-Medium';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.11px;
    text-align: center;
    color: $white;
    text-transform: uppercase;

    border-radius: 8px;
    background-color: #031d30;
    width: 100%;
    height: 60px;
    display: inline-block;
    padding: 24px 0;
    @media (min-width: 768px) {
        font-size: 9px;
        line-height: 0.56;
        letter-spacing: 1.58px;

        width: 165px;
        height: 41px;
        padding: 17px 0;
    }
    @media (min-width: 1280px) {
    }
    @media (min-width: 1920px) {
        width: 243px;
        height: 60px;
        font-size: 12px;
        line-height: 1;
        letter-spacing: 2.11px;
        padding: 24px 0;
    }
    &:hover, &:active {
        color: $white;
        text-decoration: none;
    }
}

.open-link-icon {
    margin-bottom: 4px;
    @media (min-width: 768px) {
        margin-bottom: 3px;
    }
    @media (min-width: 1280px) {
    }
    @media (min-width: 1920px) {
        margin-bottom: 4px;
    }
}
