$editorial-size: 1440px;

.home__editorial {
    max-width: $editorial-size;
    margin: 0 auto;
}

.main-editorial {
    position: relative;

        .ctas__wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 8.25rem;
        counter-reset: section;
        list-style: none;
        padding: 0;
        margin: 0;

        a {
            margin-bottom: 1.25rem;
            text-decoration: none;
            cursor: pointer;
            line-height: 1.83;
            color: $white;
            text-transform: uppercase;
            font-size: 0.75rem;
            font-family: $archivo;
            display: block;

            &::before {
                counter-increment: section;
                content: "0" counter(section);
                margin-right: 1rem;
            }
        }
    }
}

.main-editorial__img {
    position: relative;
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, .5);
    }
    .mobile {
        display: none;

        @include media-breakpoint-down(xs) {
            display: block;
        }
    }
    
    .desktop {

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }
    
    img {
        width: 100%;
    }
}

.main-editorial__info {
    position: absolute;
    bottom: 0;
    left: 6.5%;
    right: 0;
    @include flexrow;

    h2 {
        color: $white;
        letter-spacing: 1px;
        font-family: $butler;

        @include media-breakpoint-down(xs) {
            padding-bottom: 1rem;
            line-height: 1.32;
        }
    }
}

.editorial--text {
    width: 44%;
    margin-right: 4%;
    margin-bottom: 5%;

    @include media-breakpoint-down(sm) {
        width: 80%;
    }

    @include media-breakpoint-down(xs) {
        width: 100%;
        margin-bottom: 2.5rem;
    }

    p {
        color: $white;
        margin-top: 1.6rem;
        margin-bottom: 1.5rem;
        line-height: 1.71;
        letter-spacing: 0.8px;
        font-family: $archivo;

        @include media-breakpoint-down(xs) {
                display: none;
        }
    }

                        
    a {
        text-decoration: none;
        color: $white;
        font-family: $archivo;
    }
}

.editorial--ctas {
    width: 50%;
    padding-top: 4.6875rem;

    @include media-breakpoint-down(lg) {
        display: none;
    }
}