.shop-by-collection__items {
    @include custom-scroll();
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    overflow: auto;
    padding-bottom: 24px;

    &::-webkit-scrollbar-track {
        margin-left: 12px;
        margin-right: 12px;
    }
}

.sbc-item {
    flex: 0 0 auto;
    overflow: hidden;
    width: 300px;
    height: 450px;
    position: relative;
    display: flex;
    align-items: flex-end;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

.sbc-item__media-wrapper {
    position: absolute;
    inset: 0;
}

.sbc-item__video-block {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    transition: all 0.5s;

    video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.sbc-item__background-img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    inset: 0;
}

.sbc-item__content {
    color: $white;
    position: relative;
    display: flex;
    align-items: flex-start;
    min-height: 260px;
    width: 100%;
    padding: 96px 24px 24px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
}

.sbc-item__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.sbc-item__content-title {
    font-size: 20px;
    font-family: $helveticaneue;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0.02em;
    margin: 0;
}

.sbc-item__content-inner {
    display: flex;
    flex-direction: column;
}

.sbc-item__content-body {
    font-family: $helveticaneue;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.45;
    letter-spacing: 0.03em;
    transition: all 0.5s;
    max-height: 300px;
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
}

.sbc-item__button {
    background: transparent;
    border: none;
    text-decoration: none;
    padding: 14px 0 12px;
    font-family: $helveticaneue;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.45;
    letter-spacing: 0.03em;
    margin-right: auto;
    display: inline-flex;

    a:is(:hover, :focus, :active) & {
        text-decoration: underline;
    }
}

@include media-breakpoint-down(lg) {
    .sbc-item__video-block {
        opacity: 0;
    }
}

@include media-breakpoint-up(xl) {
    .shop-by-collection__items {
        width: 100%;
        height: 510px;
        gap: 0;
        overflow: hidden;
        padding: 0;
        background: $black;
    }

    .sbc-item {
        height: 100%;
        transition: all 0.5s;
        flex: 2;

        &:not(:hover):not(:focus-within) {
            width: auto;
            flex: 1;

            .shop-by-collection__items:is(:focus-within, :has(.sbc-item:hover)) & {
                opacity: 0.5;
            }

            .sbc-item__video-block {
                opacity: 0;
            }

            .sbc-item__content-body {
                max-height: 0;
                margin-bottom: 0;
            }
        }
    }

    .sbc-item__content {
        padding: 128px 48px 32px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.65) 100%);
    }

    .sbc-item__content-title {
        font-size: 24px;
    }

    .sbc-item__content--body {
        font-size: 16px;
    }

    .sbc-item__button {
        font-family: $helveticaneueMd;
        font-weight: 500;
        padding: 15px 0;
    }
}