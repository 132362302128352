.main-hero-carousel {
    &.min-h-600 {
        min-height: 600px;
    }
}

.main-hero__carousel {
    position: relative;
    margin-top: 0;
    padding-top: 15px;
    @media (min-width: 768px) {
        padding-top: 20px
    }
    @media (min-width: 1280px) {
        padding-top: 0;
        
    }

    .slick-prev {
        left: 20px;
        top: 26.5%;
        color: $white;
        width: 14px;
        height: 26px;
        @media (min-width: 768px) {
            top: 32.5%;
            width: 15px;
            height: 27px;
        }
        @media (min-width: 1280px) {
            top: 47.2%;
            left: 48px;
        }
        @media (min-width: 1920px) {
            left: 73px;
            width: 22px;
            height: 41px; 
        }
        background: url('../images/icons/left-arrow-icon.svg') center center no-repeat;
        background-size: cover;
        opacity: 1;  
        position: absolute;
        &::before {
            display: none;
        }
    }

    .slick-next {
        right: 20px;
        top: 26.5%;
        color: $white;
        width: 14px;
        height: 26px;
        position: absolute;
        @media (min-width: 768px) {
            top: 32.5%;
            width: 15px;
            height: 27px;
        }
        @media (min-width: 1280px) {
            top: 47.2%;
            right: 48px;
        }
        @media (min-width: 1920px) {
            right: 73px;
            width: 22px;
            height: 41px;  
        }
        &::before {
            display: none;
        }
        background: url('../images/icons/right-arrow-icon.svg') center center no-repeat;
        background-size: cover;
        opacity: 1;
    }

    .slick-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 335px;
        @media (min-width: 768px) {
            bottom: 306px;
        }
        @media (min-width: 1280px) {
            bottom: 21px;
            top: auto;
            margin-bottom: 0;
        }
        @media (min-width: 1920px) {
            margin-bottom: 0;
            bottom: 31px;
        }
    }

    @media (max-width: 1279.98px) {
        max-width: calc(100% - 40px);
        margin: auto;
    }

    .desktop {
        @media (max-width: 767.98px) {
            display: none;
        }
    }

    .mobile {
        @media (min-width: 768px) {
            display: none;
        }
    }
}


.main-hero__carousel-item {
    position: relative;
    display:inline-block; //added-new
    @media (min-width: 1280px) {
        margin: 0 8px;
    }
    @media (min-width: 1920px) {
        margin: 0 10px;
    }
    .main-hero__carousel-image {
        position: relative;
        &:after{
            content:'';
            position:absolute;
            left:0; top:0;
            width:100%; height:100%;
            display:inline-block;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(128, 128, 128, 0) 74%);
            border-radius: 7px;
            @media (min-width: 768px) {
                border-radius: 6px;
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(67, 67, 67, 0.52) 69%, rgba(0, 0, 0, 0.71));
            }
            @media (min-width: 1920px) {
                border-radius: 8px;
            }
        }        
    }
        
    img {
        border-radius: 7px;
        width: 100%;
        @media (min-width: 768px) {
            border-radius: 6px;
        }
        @media (min-width: 1280px) {
            border-radius: 6px;
        }
        @media (min-width: 1920px) {
            border-radius: 8px;
        }
    }
}

.main-hero__carousel-item-content-center {

    @media (min-width: 1280px) {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
    }
    @media (min-width: 1920px) {
        bottom: 72px;
    }

    .main-hero__carousel-text , .main-hero__carousel-collections{
        @media (min-width: 1280px) {
            text-align: center;
        }
    }
}


.main-hero__carousel-item-content-topleft {

    @media (min-width: 1280px) {
        position: absolute;
        top: 20px;
        left: 21px;
        
    }
    @media (min-width: 1920px) {
        top: 29px;
        left: 32px;
    }

    .main-hero__carousel-text , .main-hero__carousel-collections{
        @media (min-width: 1280px) {
            text-align: left;
        }
    }
}

.main-hero__carousel-item-content-bottomleft {

    @media (min-width: 1280px) {
        position: absolute;
        left: 20px;
        bottom: 21px;
    }
    @media (min-width: 1920px) {
        bottom: 31px;
        left: 31px;
    }

    .main-hero__carousel-text , .main-hero__carousel-collections{
        @media (min-width: 1280px) {
            text-align: left;
        }
    }
}

.main-hero__carousel-item-content-topright {

    @media (min-width: 1280px) {
        position: absolute;
        top: 20px;
        right: 22px;
        
    }
    @media (min-width: 1920px) {
        top: 29px;
        right: 32px;
    }

    .main-hero__carousel-text , .main-hero__carousel-collections{
        @media (min-width: 1280px) {
            text-align: right;
        }
    }
}

.main-hero__carousel-item-content-bottomright {

    @media (min-width: 1280px) {
        position: absolute;
        bottom: 21px;
        right: 22px;
        
    }
    @media (min-width: 1920px) {
        bottom: 31px;
        right: 31px;
    }
    .main-hero__carousel-text , .main-hero__carousel-collections{
        @media (min-width: 1280px) {
            text-align: right;
        }
    }
}

.main-hero__carousel-item-content-center +  .main-hero__carousel-cta {
    @media (min-width: 1280px) and (max-width: 1919.98px)  {
        right: 21px;    
    }
}

.main-hero__carousel-item-content-bottomright + .main-hero__carousel-cta {
    @media (min-width: 1280px) {
        position: absolute;
        bottom: 21px;
        left: 21px;    
    }
    @media (min-width: 1920px) {
        position: absolute;
        bottom: 31px;
        left: 31px;    
    }
}
.main-hero__carousel-collections {
        font-family: 'Archivo-Regular';
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 2.11px;
        text-align: center;
        color: $black;
        text-transform: uppercase;
        margin: 28px 0 16px;

    @media (min-width: 768px) {
        margin: 29px 0 17px;
    }

    @media (min-width: 1280px) {
        font-family: 'Archivo-SemiBold';
        font-size: 8px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.41px;
        text-align: center;
        color: $white;
        margin: 0 0 8px 0;
    }
    @media (min-width: 1920px) {
        font-family: 'Archivo-SemiBold';
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 2.11px;
        text-align: center;
        color: $white;
        margin: 0 0 11px 0;
    }
}
.main-hero__carousel-text {
    font-family: 'Butler-Light';
    font-size: 45px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.84;
    letter-spacing: -1.35px;
    text-align: center;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 103px;

    @media (min-width: 768px) {
        margin-bottom: 108px;
    }

    @media (min-width: 1280px) {
        font-size: 46px;
        line-height: 0.83;
        letter-spacing: -1.38px;
        color: $white;
        margin-bottom: 0;
    }
    @media (min-width: 1920px) {
        font-size: 71px;
        line-height: 0.82;
        letter-spacing: -2.13px;
        color: $white;
    }
}

.main-hero__carousel-cta {

    width: 100%;
    height: 60px;
    border-radius: 8px;
    background-color: #000;
    font-family: 'Archivo-Medium';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.11px;
    text-align: center;
    color: $white;
    display: inline-block;
    text-transform: uppercase;
    padding: 24px 0;
    
    &:hover {
        text-decoration: none;
        @media (max-width: 1279.98px) {
            color: $white;
        }
    }

    @media (min-width: 1280px) {
        position: absolute;
        top: auto;
        bottom: 21px;
        right: 22px;
        width: 169px;
        height: 41px;
        border-radius: 6px;
        background-color: $white;
        color: $black;
        font-size: 9px;
        opacity: 1;
        line-height: 0.56;
        letter-spacing: 1.58px;
        padding: 18px 0;
    }

    @media (min-width: 1920px) {
        width: 225px;
        height: 60px;
        border-radius: 8px;
        line-height: 1;
        letter-spacing: 2.11px;
        bottom: 31px;
        right: 32px;
        padding: 25px 0;
    }
}


.slick-dots {
    .slick--simple-dots & {
            button {
                padding: 0 5px;
                @media (min-width: 1920px) {
                    padding: 0 6px;
                }
    
                &:after {
                    display: block;
                    width: 5px;
                    height: 5px;
                    border-radius: 100px;
                    content: '';
                    @media (min-width: 1920px) {
                        width: 7px;
                        height: 7px;
                    }
                }
            }
        }
    .slick--white-dots & {
            button:after {
                background-color: white;
            opacity: 0.4;
            }
    
            .slick-active {
                button:after {
                   background-color: white;
                opacity: 1;
                }
            }
    }
}

.desktop-show {
    display: none;
    @media (min-width: 1280px) {
        display: block;
    }
}

.slick-slide .fadeinDown {
    opacity: 0;
    transform: translateY(-30px);
    transition: all 2s ease;
}
.slick-current .fadeinDown {
    opacity: 1;
    transform: translateY(0);
}
.slick-slide .fadeinDown2 {
    opacity: 0;
    transform: translateY(-30px);
    transition: all 2.5s ease;
}
.slick-current .fadeinDown2 {
    opacity: 1;
    transform: translateY(0);
}