.explore-collection{
    margin: 61px 20px 0;
    @media (min-width: 768px) {
        margin: 64px 20px 0;
    }
    @media (min-width: 1280px) {
        
        margin: 49px 26px 0 27px;
    }
    @media (min-width: 1920px) {
        margin: 73px 41px 0;
    }
}
.explore-collection__info {
    @media (max-width: 767.98px) {
        display: block !important
    }
    
}
.explore-collection__section {
    margin-bottom: 76px;
    @media (min-width: 768px) {
        margin-bottom: 50.5px;
    }
    @media (min-width: 1280px) {
        position: relative;
        margin-bottom: 51.5px;
    }
    @media (min-width: 1920px) {
        margin-bottom: 75.5px;
    }
    &:last-child {
        @media (max-width: 767.98px) {
            margin-bottom: 64.5px;
        }
    }
}

.explore-collection__title {
    font-family: 'Archivo-ExtraLight';
    font-size: 16px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: 2.4px;
    text-align: center;
    color: $black;
    text-transform: uppercase;
    margin: 0 0 49px;
    
    @media (min-width: 768px) {
        margin: 0 0 64px;
    }
    @media (min-width: 1280px) {
        margin: 0 0 49px;
    }
    @media (min-width: 1920px) {
        font-size: 20px;
        line-height: 1.55;
        letter-spacing: 3px; 
        margin: 0 0 60px;
    }
}

.explore-collection__section {
    &:nth-child(2) {
        @media (min-width: 768px) {
            margin: 0 16px 50.5px;
        }
        @media (min-width: 1280px) {
            margin: 0 27px 51.5px;;
        }
        @media (min-width: 1920px) {
            margin: 0 40px 75.5px;
        }
    }
}
.explore-collection__content {

    @media (min-width: 1280px) {
        position: absolute;
        bottom: 18px;
        left: 17px;
    }
    @media (min-width: 1920px) {
        bottom: 27px;
        left: 26px;
    }

}
.explore-collection__content--title {
    font-family: 'Butler-Light';
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: 0.64px;
    text-align: left;
    color: $black;
    text-transform: uppercase;
    margin-top: 29px;

    @media (min-width: 768px) {
        font-size: 24px;
        line-height: 1.58;
        letter-spacing: 0.48px;
        margin-top: 25px;
    }
    @media (min-width: 1280px) {
        font-size: 28px;
        line-height: 1.36;
        letter-spacing: 0.56px;
        color: $white;   
        margin-top: 0;
    }
    @media (min-width: 1920px) {
        font-size: 35px;
        line-height: 1.09;
        letter-spacing: 0.7px;   
    }
}

.explore-collection__content--description {
    font-family: 'Archivo-ExtraLight';
    font-size: 16px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.8px;
    text-align: left;
    color: $black;
    margin-top: 14px;
    @media (min-width: 768px) {
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 0.36px;
        margin-top: 13px;
        margin-right: 20px;
    }
    @media (min-width: 1280px) {
        font-family: 'Archivo-Light';
        color: $white;
        margin-top: 10px;
        margin-right: 90px;
    }
    @media (min-width: 1920px) {
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: 0.48px;  
        margin-top: 13px;
        margin-right: 226px;
    }

}
.explore-collection__content--cta {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    background-color: $black;

    font-family: 'Archivo-Medium';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.11px;
    text-align: center;
    color: $white;
    display: block;
    padding: 24px 0;
    margin-top: 24px;
    text-transform: uppercase;
    &:hover {
        text-decoration: none;
        color: $white;
        @media (min-width: 1280px) {
            color: $black;
        }
    }

    @media (min-width: 768px) {
        width: 100%;
        height: 41px;
        border-radius: 6px;

        font-size: 9px;
        line-height: 0.56;
        letter-spacing: 1.58px;
        margin-top: 21px;
        padding: 18px 0;
    }
    @media (min-width: 1280px) {
        min-width: 119px;
        width: auto;
        height: 37px;
        border-radius: 6px;
        background-color: $white;
        color: $black;
        margin-top: 12px;
        padding: 16px 22px 16px 21px;
        display: inline-block;
    }
    @media (min-width: 1920px) {
        min-width: 153px;
        width: auto;
        height: 40px;
        border-radius: 8px;

        font-size: 12px;
        line-height: 1;
        letter-spacing: 2.11px;
        margin-top: 28px;
        padding: 14px 40px;
    }

}

.explore-collection__img {
    position: relative;
    &:after {
        content:'';
        position:absolute;
        left:0; 
        top:0;
        width:100%; 
        height:100%;
        display:inline-block;
        background: $black;
        opacity: 0.4;
        border-radius: 8px;
        @media (min-width: 768px) {
            border-radius: 6px;
        }
        @media (min-width: 1280px) {
            border-radius: 6px;
        }
        @media (min-width: 1920px) {
            border-radius: 8px;
        }
    }

    img {
        border-radius: 8px;
        @media (min-width: 768px) {
            border-radius: 6px;
        }
        @media (min-width: 1280px) {
            border-radius: 6px;
        }
        @media (min-width: 1920px) {
            border-radius: 8px;
        }
    }
}

.explore-collection__link {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}