@import "bootstrap/scss/functions";// New Changes Addition


/* FRETTE: Start Variables
-------------------------- */

@import "fonts";

@import "bootstrap/scss/functions";// New Changes Addition


$updated-grid-breakpoints: (
    md: 768px,
    lg: 1280px,
    xl: 1920px
) !default;

$updated-grid-breakpoints_with_minMax: (
    md: 767.98px,
    lg: 1279.98px,
    xl: 1919.98px
)!default;


/* FRETTE: End Variables
------------------------ */





// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: "../fonts" !default;

// flag icons location in relation to target location of the css file.
$flag-icon-css-path: "../fonts/flags/" !default;

//Colors Update: Start
$white: #ffffff;
$alabaster: #f8f8f8;
$cararra: #efede9;
$mercury: #eaeaea;
$alto: #dbdbdb;
$silver: #bcbcbc;
$boulder: #757575;
$rollingstone: #71767c;
$emperor: #505050;
$shark: #212529;
$fretteblue: #6b95c2;
$navyblue: #0b1d2e;
$greyaccent: #dbd8e0;
$sorrellbrown: #ccb88d;

$primary: $fretteblue;
$secondary: $navyblue;

//used variable colors //TODO: Update?
$grey: #838383 !default;
$grey1: #F6F6F6 !default;
$grey2: #E9E9E9 !default;
$grey3: #ccc !default;
$grey4: #999 !default;
$grey5: #666 !default;
$grey6: #444 !default;
$grey7: #222 !default;
$grey8: #333 !default;
$grey9: #e6e2e2;
$grey10: #d0d0d0;
$grey11: #757777;
$grey13: #f0f0f0;
$grey15: #757b82;
$greye8: #e8e8e8;
$greyed: #ededed;
$greyb4: #b4b4b4;
$greyaf: #afafaf;
$grey83: #838383;
$grey29: #292929;
$grey57 : #575757;
$grey6C: #6c6c6c;
$grey5c: #5c5c5c;
$grey77: #777777;
$grey-6C: #6c6c6c;
$greyDB: #dbdbdb;
$grey81: #818e97;
$black: #000 !default;
$black-light: #777;
$blue30: #031d30;
$bluecharcoal : #021623;
$bluewhale : #042b43;
$coupon-warning-red: #CC0000;
$dark-blue: #031d30;
$darkgreen: #031e2f;
$light-blue: #7ed0ee !default;
$matterhorn: #505050;
$navi-blue: #031d30;
$very-light-pink: #cacaca;
$color_name-031d30: #031d30;
$horizontal-border-grey: $grey4 !default;
$horizontal-rule-grey: $grey3 !default;
$dark-gray: $grey6 !default;
$darker-gray: $grey7 !default;
$slightly-darker-gray: $grey4 !default;

//Colors Update: End

$grid-gutter-width: 1.625rem !default;

$grid-breakpoints: (
    xs: 0,
    vs: 480px,
    sm: 544px,
    md: 769px,
    lg: 992px,
    xl: 1200px
) !default;

$container-max-widths: (
    md: 992px,
    lg: 1200px,
    xl: 1304px
) !default;

$border-radius: 0.1875rem !default;
$border-radius-lg: $border-radius !default;
$border-radius-sm: $border-radius !default;

$btn-border-radius: 0 !default;

$state-danger-text: #fff !default;
$alert-success-text: #fff !default;

// Font sizes
$base16-14px: 0.875em !default;

// Font Line Height
$font-line-height-reduce: 0.8;
$font-line-height-reset: 1;
$font-line-height-xsmall: 1.11;
$font-line-height-small: 1.21;
$font-line-height-normal: 1.31;
$font-line-height-medium: 1.41;
$font-line-height-large: 1.51;
$font-line-height-xlarge: 1.9;

// Letter Spacing
$letter-spacing-reduce: 0.5px;
$letter-spacing-small: 0.9px;
$letter-spacing-medium: 0.7px;
$letter-spacing-reset: 1px;
$letter-spacing-large: 1.5px;
$letter-spacing-xxlarge: 2.4px;

// Bootstrap overrides
$body-bg: $white !default;
$card-cap-bg: $white !default;

// Tabs
$nav-tabs-border-width: 0 !default;
$nav-tabs-border-radius: 0 !default;
$nav-tabs-active-link-hover-bg: transparent !default;

// breadcrumb
$breadcrumb-bg: transparent !default;

// table border
$table-border-color: $grey3 !default;

$svg-check: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;
$svg-check-white: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;

$svg-arrow-down: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_1257_11095" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%231C1B1F"/></mask><g mask="url(%23mask0_1257_11095)"><path d="M11.9999 15.0542L6.34619 9.40043L7.39994 8.34668L11.9999 12.9467L16.5999 8.34668L17.6537 9.40043L11.9999 15.0542Z" fill="%231C1B1F"/></g></svg>') !default;
$svg-arrow-up: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_1232_4071" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%231C1B1F"/></mask><g mask="url(%23mask0_1232_4071)"><path d="M11.9999 10.4542L7.39994 15.0542L6.34619 14.0004L11.9999 8.34668L17.6537 14.0004L16.5999 15.0542L11.9999 10.4542Z" fill="%231C1B1F"/></g></svg>') !default;
$svg-arrow-left: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><mask id="mask0_1145_16217" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20"><rect width="20" height="20" fill="%23212529"/></mask><g mask="url(%23mask0_1145_16217)"><path d="M13 17.711L5.28857 9.99955L13 2.28809L14.1202 3.40829L7.52878 9.99955L14.1202 16.5908L13 17.711Z" fill="%23212529"/></g></svg>') !default;
$svg-arrow-right: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><mask id="mask0_1145_16218" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20"><rect width="20" height="20" fill="%23212529"/></mask><g mask="url(%23mask0_1145_16218)"><path d="M5.99203 17.711L4.87183 16.5908L11.4631 9.99955L4.87183 3.40829L5.99203 2.28809L13.7035 9.99955L5.99203 17.711Z" fill="%23212529"/></g></svg>') !default;


/*------------------------------------*\
  #FORMS
\*------------------------------------*/
$form-group-margin-bottom: 1.25rem;
$enable-rounded: false;
$input-border-radius: 0;

/*------------------------------------*\
  #TRANSITIONS
\*------------------------------------*/

// Transition durations
// **** If this is updated, make sure FEDConstants.js is also updated. ***
$transition-speed-slower    :   1s;
$transition-speed-slow      :   600ms;
$transition-speed-mid-slow  :   500ms;
$transition-speed-normal    :   426ms;
$transition-speed-medium    :   320ms;
$transition-speed-fast      :   213ms;
$transition-speed-faster    :   107ms;
$transition-speed-fastest   :   54ms;

// Transition easing
$transition-ease-default    :   ease-in-out;
$transition-ease-in         :   ease-in;
$transition-ease-out        :   ease-out;
$transition-ease-bezier     :   cubic-bezier(.4,.9,.3,1);
$transition-ease-bounce     :   cubic-bezier(.4,.9,.3,1.25);
$transition-ease-big-bounce :   cubic-bezier(.4,.9,.3,2.25);